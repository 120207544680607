import React, { useState } from "react";
import ListadoCesiones from "./ListadoCesiones";
import DetalleCesion from "./DetalleCesion";

const IntegradorCesion = () => {
  const [cesionSeleccionada, setCesionSeleccionada] = useState(null);

  return (
    <div >
      {cesionSeleccionada ? (
        <DetalleCesion
          cesion={cesionSeleccionada}
          onVolver={() => setCesionSeleccionada(null)}
        />
      ) : (
        <ListadoCesiones onVerDetalle={setCesionSeleccionada} />
      )}
    </div>
  );
};

export default IntegradorCesion;
