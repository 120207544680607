import React, { useState, useEffect, useContext } from "react";
import { withRouter } from "react-router-dom";
import clienteAxios from "../../config/axios";
import { CRMContext } from "../../context/CRMContext";
import Spinner from "../layout/Spinner";
import moment from "moment";
import { get } from "../../config/apiHelper";

const FichaLote = ({ match, history }) => {
  const [auth] = useContext(CRMContext);
  const [lote, setLote] = useState({ loading: true });
  const [operacionesRelacionadas, setOperacionesRelacionadas] = useState([]);

  const { id } = match.params;
  const token = auth.token || localStorage.getItem("token");

  // Consultar datos del lote
  const consultarAPILote = async () => {
    try {
      const loteDB = await clienteAxios.get(`/lote/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      setLote({
        loading: false,
        ...loteDB.data,
      });
    } catch (error) {
      if (error.response?.status === 500) {
        localStorage.clear();
        history.push("/login");
      }
      console.error(error);
    }
  };

  const getLoteOperaciones = async () => {
    try {
      const response = await get(`operaciones/lote/${id}`)

      setOperacionesRelacionadas(response);
    } catch (error) {
      if (error.response?.status === 500) {
        localStorage.clear();
        history.push("/login");
      }
      console.log(error);
    }
  }

  useEffect(() => {
    consultarAPILote();
    getLoteOperaciones();
  }, []);

  if (lote.loading) {
    return <Spinner />;
  }

  return (
    <div className="card">
      <div className="card-header">
        <h3 className="card-title">Ficha del Lote</h3>
        <button
          className="btn btn-secondary float-right"
          onClick={() => history.goBack()}
        >
          Volver
        </button>
      </div>
      <div className="card-body">
        <div className="row">
          {/* Información del lote */}
          <div className="col-md-6">
            <p>
              <strong>Desarrollo:</strong> {lote.barrio?.nombre || "N/A"}
            </p>
            <p>
              <strong>Número de Lote:</strong> {lote.numeroLote || "N/A"}
            </p>
            <p>
              <strong>Número de Manzana:</strong> {lote.numeroManzana || "N/A"}
            </p>
            <p>
              <strong>Superficie:</strong> {lote.superficie || "N/A"} m²
            </p>
            <p>
              <strong>Precio de Lista:</strong> ${lote.precioLista?.toLocaleString() || "N/A"}
            </p>
          </div>
          <div className="col-md-6">
            <p>
              <strong>Tipología:</strong> {lote.tipologia?.tipo || "N/A"}
            </p>
            <p>
              <strong>Estado:</strong> {lote.estado?.estado || "N/A"}
            </p>
            <p>
              <strong>Orientación:</strong> {lote.orientacion || "N/A"}
            </p>
            <p>
              <strong>Es Inversor:</strong> {lote.isInversor ? "Sí" : "No"}
            </p>
            {lote.isInversor && (
              <p>
                <strong>Inversionista:</strong> {lote.inversor?.nombreCorto || "N/A"}
              </p>
            )}
          </div>
        </div>

        {/* Comentarios */}
        <div className="row mt-4">
          <div className="col-md-12">
            <h4>Comentarios:</h4>
            {lote.comentarios?.length > 0 ? (
              <ul className="list-group">
                {lote.comentarios.map((comentario, index) => (
                  <li key={index} className="list-group-item">
                    <p>
                      <strong>Fecha:</strong>{" "}
                      {moment(comentario.fecha).format("DD-MM-YYYY - HH:mm")}
                    </p>
                    <p>
                      <strong>Usuario:</strong> {comentario.usuario?.nombre || "N/A"}{" "}
                      {comentario.usuario?.apellido || "N/A"}
                    </p>
                    <p>{comentario.comentario}</p>
                  </li>
                ))}
              </ul>
            ) : (
              <p>No hay comentarios registrados.</p>
            )}
          </div>
        </div>

        {/* Operaciones relacionadas */}
        <div className="row mt-4">
          <div className="col-md-12">
            <h4>Operaciones Relacionadas:</h4>
            {operacionesRelacionadas?.length > 0 ? (
              <ul className="list-group">
                {operacionesRelacionadas.map((operacion, index) => (
                  <li key={index} className="list-group-item">
                    <p>
                      <strong>Fecha:</strong>{" "}
                      {moment(operacion.fechaAlta).format("DD-MM-YYYY")}
                    </p>
                    <p>
                      <strong>Tipo:</strong> {operacion.tipo || "N/A"}
                    </p>
                    <p>
                      <strong>Estado:</strong> {operacion.estado?.estado || "N/A"}
                    </p>
                  </li>
                ))}
              </ul>
            ) : (
              <p>No hay operaciones relacionadas.</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(FichaLote);
