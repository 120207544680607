import React from 'react';

import Address from './Address';
import Phone from './Phone';
import NameFisicas from './NameFisicas';
import NameJuridicas from './NameJuridicas';
import DocumentsFisicas from './DocumentsFisicas';
import DocumentsJuridicas from './DocumentsJuridicas';
import Nacionalidad from './Nacionalidad';
import EstadoCivil from './EstadoCivil';
import Email from './Email';
import FechaNacimiento from './FechaNacimiento';

const ShowPersona = (props) => {
  const { client } = props;
  return (
    <>
      { client.razonSocial ?
        <NameJuridicas
          client={client}
        /> :
        <NameFisicas
          client={client}
        />
      }
      { client.razonSocial ?
        <DocumentsJuridicas
          client={client}
        /> :
        <DocumentsFisicas
          client={client}
        />
      }
      { client.razonSocial ?
        null : 
        <Nacionalidad
          client={client}
        />
      }
      <Address
        client={client}
        />
      <Phone
        client={client}
      />
      <Email
        client={client}
      />
      <FechaNacimiento
        client={client}
      />
      { client.razonSocial ?
        null : 
        <EstadoCivil
          client={client}
        />
      }
    </>
  )
}

export default ShowPersona