import React, { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component';
import Select from 'react-select';
import { saveAs } from 'file-saver';
import listadoPreciosStore from '../../store/listadoPreciosStore';
import { get } from '../../config/apiHelper'
import Spinner from '../layout/Spinner';
import clienteAxios from '../../config/axios';
import Swal from 'sweetalert2';

const ListadoListaDePrecios = (props) => {
  const [showSpinner, setShowSpinner] = useState(false)
  const [listasDePrecios, setListasDePrecios] = useState([]);
  const { filter, setFilter } = listadoPreciosStore();
  const [barrios, setBarrios] = useState([]);
  const [usuariosAdmin, setUsuariosAdmin] = useState([]);
  const estados = ['Aplicada', 'Pendiente', 'Cancelada'];
  const [selectedBarrio, setSelectedBarrio] = useState(null);

  const getListasDePrecios = async () => {
    try {
      setShowSpinner(true);
      let url = '/api/listaDePrecios';
      if (filter) {
        url += '?';
        for (const key in filter) {
          if (filter[key]) {
            url += `${key}=${filter[key]}&`
          }
        }
      }
      const response = await get(url);
      setListasDePrecios(response);
    } catch (error) {
      console.log(error)
    } finally {
      setShowSpinner(false)
    }
  }

  useEffect(() => {
    getListasDePrecios();
    getBarrios();
    getUsuariosAdmin();
  }, []);

  useEffect(() => {
    getListasDePrecios();
  }, [filter]);


  const columns = [
    { name: 'Fecha Alta', selector: (row) => new Date(row.fechaAlta).toLocaleDateString('es-AR'), sortable: true },
    { name: 'Fecha Aplicación', selector: (row) => row.fechaAplicacion ? new Date(row.fechaAplicacion).toLocaleDateString('es-AR') : '-', sortable: true },
    { name: 'Desarrollo', selector: (row) => row.barrio?.nombre, sortable: true },
    { name: 'Usuario Alta', selector: (row) => `${row.usuarioAlta?.apellido}, ${row.usuarioAlta?.nombre}`, sortable: true },
    { name: 'Usuario Aprobador', selector: (row) => row.usuarioAplicacion ? `${row.usuarioAplicacion?.apellido}, ${row.usuarioAplicacion?.nombre}` : ' - ', sortable: true },
    { name: 'Estado', selector: (row) => row.estado, sortable: true },
    { name: 'Editar', cell: (row) => <button disabled={row.estado !== 'Pendiente'} className="btn btn-primary" onClick={() => props.history.push(`/listaDePrecios/editar/${row._id}`)}>Editar</button>, ignoreRowClick: true, allowOverflow: true, button: true },
    { name: 'Ver', cell: (row) => <button className="btn btn-primary" onClick={() => props.history.push(`/listaDePrecios/${row._id}`)}>Ver</button>, ignoreRowClick: true, allowOverflow: true, button: true }
  ];

  const getBarrios = async () => {
    try {
      const barrios = await get('/barrios')
      setBarrios(barrios)
    } catch (error) {
      console.log(error)
    }
  }

  const getUsuariosAdmin = async () => {
    try {
      const { data } = await get('/usuarios');
      const usuarios = data.filter(usuario => usuario.rol?.id === '4' && usuario.activo);
      setUsuariosAdmin(usuarios);
    } catch (error) {
      console.log(error);
    }
  }

  const getComparativo = async () => {
    try {
      Swal.fire({
        title: 'Generando comparativo...',
        text: 'Por favor, espere.',
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        }
      });

      const response = await clienteAxios.get(`/api/listaDePrecios/comparativoListas/${selectedBarrio}`, {
        responseType: 'blob',
      });

      const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      saveAs(blob, `comparativoListas_${barrios.find(barrio => barrio._id === selectedBarrio)?.nombre}.xlsx`);

      Swal.close();
    } catch (error) {
      Swal.fire({
        icon: 'info',
        title: 'Información',
        text: 'No existen listas de precios para el desarrollo seleccionado.',
      });
      console.log(error);
    }
  }


  return (
    <div className="card">
      <div className="card-header">
        <h3 className="card-title mt-2">Listas de Precios</h3>
        <button className="btn btn-primary float-right" onClick={props.history.goBack}>Volver</button>
        <button className="btn btn-success float-right mr-2"
          onClick={() => props.history.push('/listaDePrecios/nuevo')}
        >Nueva</button>
      </div>
      <div className="card-body">
        {showSpinner && <Spinner />}
        {!showSpinner &&
          <>
            <div className="row mb-4">
              <div className="col-md-2">
                <label>Desarrollo</label>
                <select className="form-control" onChange={(e) => setFilter({ ...filter, barrio: e.target.value })} value={filter.barrio}>
                  <option value="">Todos</option>
                  {barrios.map(barrio => (
                    <option key={barrio._id} value={barrio._id}>{barrio.nombre}</option>
                  ))}
                </select>
              </div>
              <div className="col-md-2">
                <label>Estado</label>
                <select className="form-control" onChange={(e) => setFilter({ ...filter, estado: e.target.value })} value={filter.estado}>
                  <option value="">Todos</option>
                  {estados.map(estado => (
                    <option key={estado} value={estado}>{estado}</option>
                  ))}
                </select>
              </div>
              <div className="col-md-2">
                <label>Usuario Alta</label>
                <select className="form-control" onChange={(e) => setFilter({ ...filter, usuarioAlta: e.target.value })} value={filter.usuarioAlta}>
                  <option value="">Todos</option>
                  {usuariosAdmin.map(usuario => (
                    <option key={usuario._id} value={usuario._id}>{`${usuario.apellido}, ${usuario.nombre}`}</option>
                  ))}
                </select>
              </div>
              <div className="col-md-2">
                <label>Usuario Aplicación</label>
                <select className="form-control" onChange={(e) => setFilter({ ...filter, usuarioAplicacion: e.target.value })} value={filter.usuarioAplicacion}>
                  <option value="">Todos</option>
                  {usuariosAdmin.map(usuario => (
                    <option key={usuario._id} value={usuario._id}>{`${usuario.apellido}, ${usuario.nombre}`}</option>
                  ))}
                </select>
              </div>
              <div className="col-md-2">
                <label>Fecha Alta Desde</label>
                <input type="date" className="form-control" onChange={(e) => setFilter({ ...filter, fechaAltaDesde: e.target.value })} value={filter.fechaAltaDesde} />
              </div>
              <div className="col-md-2">
                <label>Fecha Alta Hasta</label>
                <input type="date" className="form-control" onChange={(e) => setFilter({ ...filter, fechaAltaHasta: e.target.value })} value={filter.fechaAltaHasta} />
              </div>
              <div className="col-md-2">
                <label>Fecha Aplicación Desde</label>
                <input type="date" className="form-control" onChange={(e) => setFilter({ ...filter, fechaAplicacionDesde: e.target.value })} value={filter.fechaAplicacionDesde} />
              </div>
              <div className="col-md-2">
                <label>Fecha Aplicación Hasta</label>
                <input type="date" className="form-control" onChange={(e) => setFilter({ ...filter, fechaAplicacionHasta: e.target.value })} value={filter.fechaAplicacionHasta} />
              </div>
              <div className="col-md-12 text-right">
                <button className="btn btn-secondary mr-2" onClick={() => setFilter({})}>Limpiar Filtros</button>
              </div>
            </div>

            <DataTable
              columns={columns}
              data={listasDePrecios}
              pagination
              highlightOnHover
              pointerOnHover
              striped
              responsive
              noHeader
            />

            <div className="row">
              <div className="col-md-12">
                <label>Obtener comparativo de listas de precios</label>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <Select
                  options={barrios.map((barrio) => ({ value: barrio._id, label: barrio.nombre }))}
                  onChange={(e) => setSelectedBarrio(e.value)}
                  value={barrios.map((barrio) => ({ value: barrio._id, label: barrio.nombre })).find(barrio => barrio.value === selectedBarrio)}
                />
              </div>
              <div className="col-md-3">
                <button className="btn btn-primary"
                  disabled={!selectedBarrio}
                  onClick={getComparativo}
                >Obtener comparativo</button>
              </div>
            </div>
          </>
        }
      </div>
      <div className="card-footer"></div>
    </div>
  )
}

export default ListadoListaDePrecios