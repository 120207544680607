import React from 'react'

const DocumentsJuridicas = (props) => {
  const { client } = props;
  const { documentos } = client;
  const getCuit = (cuit) => {
    if(!cuit) {
      return '-';
    }
    if(typeof(cuit) !== 'string') {
      cuit = cuit.toString();
    }
    return `${cuit.slice(0, 2)}-${cuit.slice(2, 10)}-${cuit.slice(10)}`;
  }
  return (
    <div className='mb-4'>
      <div className="row">
        <label>Documentos:</label>
      </div>
      <div className="row">
        <div className="col-md-4">
          <label>CUIT</label>
          <input className="form-control" type="text" value={getCuit(documentos?.cuitCuil)} disabled/>
        </div>
      </div>
    </div>
  )
}

export default DocumentsJuridicas