import React, { useEffect, useState } from 'react'
import Spinner from '../layout/Spinner';
import { del, get, put } from '../../config/apiHelper';
import DataTable from 'react-data-table-component';
import Select from "react-select";
import Swal from 'sweetalert2';

const VerListaDePrecios = (props) => {
  const [listaDePrecios, setListaDePrecios] = useState({});
  const [showSpinner, setShowSpinner] = useState(false);
  const [usuariosAdmin, setUsuariosAdmin] = useState([]);
  const [usuarioAplicacion, setUsuarioAplicacion] = useState('');
  const [lotesFiltrados, setLotesFiltrados] = useState([]);
  const [filters, setFilters] = useState({});
  const [tipologias, setTipologias] = useState([]);

  

  const getListasDePrecios = async () => {
    try {
      setShowSpinner(true);
      const response = await get(`/api/listaDePrecios/${props.match.params.id}`);
      setListaDePrecios(response);
      setLotesFiltrados(response.lotes);
    } catch (error) {
      console.log(error)
    } finally {
      setShowSpinner(false)
    }
  }

  const getTipologias= async () => {
    try {
      let tipologias = await get('/tipologias');
      tipologias = tipologias.sort((a, b) => a.tipo.localeCompare(b.tipo));
      tipologias.unshift({ _id: '', tipo: 'Todas' });
      setTipologias(tipologias);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    Promise.all([getListasDePrecios(), getUsuariosAdmin(), getTipologias()])
      .then();
  }, []);

  const getRango = (string) => {
    if(typeof(string) !== 'string' || string.length === 0) {
      return null;
    }
    let [min, max] = string.split('-');
    if(!max) {
      max = min;
    }
    return [Number(min) , Number(max)];
  }

  const setFilter = (key, value) => {
    setFilters({
      ...filters,
      [key]: value
    });
  }

  useEffect(() => {
    if(Object.keys(filters).length === 0) {
      return;
    }
    const lotes = listaDePrecios.lotes.filter( lote => {
      if(filters.numeroLote) {
        const rango = filters.numeroLote;
        if(lote.lote.numeroLote < rango[0] || lote.lote.numeroLote > rango[1]) {
          return false;
        }
      }
      if(filters.manzana) {
        const rango = filters.manzana;
        if(lote.lote.numeroManzana < rango[0] || lote.lote.numeroManzana > rango[1]) {
          return false;
        }
      }
      if(filters.superficie) {
        const rango = filters.superficie;
        if(lote.lote.superficie < rango[0] || lote.lote.superficie > rango[1]) {
          return false;
        }
      }
      if(filters.tipologia) {
        if(lote.lote.tipologia._id !== filters.tipologia) {
          return false;
        }
      }
      return true;
    });
    setLotesFiltrados(lotes);
  }, [filters]);


  const columnas = [
    { name: "N° Lote", selector: (row) => row.lote.numeroLote, sortable: true },
    { name: "N° Manzana", selector: (row) => row.lote.numeroManzana, sortable: true },
    { name: "Superficie", selector: (row) => row.lote.superficie, sortable: true },
    { name: "Estado", selector: (row) => row.lote.estado.estado, sortable: true },
    { name: "Tipología", selector: (row) => row.lote.tipologia.tipo, sortable: true },
    {
      name: "Precio Lista",
      selector: (row) => `$${row.precioAnterior?.toFixed(2)}`,
      sortable: true,
    },
    {
      name: "Precio Nuevo",
      selector: (row) => `$${row.precioNuevo?.toFixed(2)}`,
      sortable: true,
    },
    {
      name: "Variación",
      selector: (row) => `${((row.precioNuevo / row.precioAnterior - 1) * 100)?.toFixed(2)}%`,
      sortable: true,
    },
    {
      name: "m2 anterior",
      selector: (row) => `$ ${(row.precioAnterior / row.lote?.superficie).toFixed(2)}`,
    },
    {
      name: "m2 nuevo",
      selector: (row) => `$ ${(row.precioNuevo / row.lote?.superficie).toFixed(2)}`,
    },
  ];

  const paginationComponentOptions = {
    rowsPerPageText: "Filas por página",
    rangeSeparatorText: "de",
    selectAllRowsItem: true,
    selectAllRowsItemText: "Todos",
  };

  const deleteListaDePrecios = async () => {
    const result = await Swal.fire({
      title: '¿Estás seguro?',
      text: "Esta acción cancelará la lista de precios.",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí, cancelar',
      cancelButtonText: 'Cancelar'
    });

    if (result.isConfirmed) {
      try {
        setShowSpinner(true);
        await del(`/api/listaDePrecios/${props.match.params.id}`);
        props.history.push('/listaDePrecios');
      } catch (error) {
        console.log(error);
      } finally {
        setShowSpinner(false);
      }
    }
  }

  const aplicarListaDePrecios = async () => {
    const result = await Swal.fire({
      title: '¿Estás seguro?',
      text: "Esta acción aplicará la lista de precios.",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí, aplicar',
      cancelButtonText: 'Cancelar'
    });

    if (result.isConfirmed) {
      try {
        setShowSpinner(true);
        await get(`/api/listaDePrecios/apply/${props.match.params.id}`);
        props.history.push('/listaDePrecios');
      } catch (error) {
        console.log(error);
      } finally {
        setShowSpinner(false);
      }
    }
  }

  const getUsuariosAdmin = async () => {
    try {
      const { data } = await get('/usuarios');
      const usuarios = data.filter(usuario => usuario.rol?.id === '4' && usuario.activo);
      setUsuariosAdmin(usuarios);
    } catch (error) {
      console.log(error);
    }
  }

  const enviarLinkAcceso = async () => {
    try {
      const response = await get(`/api/listaDePrecios/generateUrl/${usuarioAplicacion}/${props.match.params.id}`);
      if(response && response.estado === 'ok') {

        Swal.fire({
          icon: 'success',
          title: 'Link generado',
          text: response.msg,
        });
      }
    } catch (error) {
      console.log(error);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'No se pudo generar el link de acceso',
      });
    }
  }

  const rulesColumns = [
    { name: 'Tipología', selector: (row) => row.tipologia ?? ' - ', sortable: true },
    { name: 'Rango Lotes', selector: (row) => row.rangoLotes && row.rangoLotes[0] && row.rangoLotes[1] ? `${row.rangoLotes[0]} - ${row.rangoLotes[1]}` : ' - ', sortable: true },
    { name: 'Rango Manzana', selector: (row) => row.rangoManzana && row.rangoManzana[0] && row.rangoManzana[1] ? `${row.rangoManzana[0]} - ${row.rangoManzana[1]}` : ' - ', sortable: true },
    { name: '% Incremento', selector: (row) => row.porcentaje ?? ' - ', sortable: true },
    { name: '$ Incremento', selector: (row) => row.valorFijo ?? ' - ', sortable: true },
    { name: '$ Metro Cuadrado', selector: (row) => row.metroCuadrado ?? ' - ', sortable: true },
  ];

  const informacionColumns = [
    { name: 'Fecha Alta', selector: (row) => new Date(row.fechaAlta).toLocaleDateString('es-AR'), sortable: true },
    { name: 'Fecha Aplicación', selector: (row) => row.fechaAplicacion ? new Date(row.fechaAplicacion).toLocaleDateString('es-AR') : '-', sortable: true },
    { name: 'Desarrollo', selector: (row) => row.barrio?.nombre, sortable: true },
    { name: 'Usuario Alta', selector: (row) => `${row.usuarioAlta?.apellido}, ${row.usuarioAlta?.nombre}`, sortable: true },
    { name: 'Usuario Aprobador', selector: (row) => row.usuarioAplicacion ? `${row.usuarioAplicacion?.apellido}, ${row.usuarioAplicacion?.nombre}` : ' - ', sortable: true },
    { name: 'Estado', selector: (row) => row.estado, sortable: true },
  ];

  return (
    <div className="card">
      <div className="card-header">
        <h3 className="card-title">Lista de Precios</h3>
        <button className="btn btn-primary float-right" onClick={props.history.goBack}>Volver</button>
      </div>
      <div className="card-body">
        {showSpinner && <Spinner />}
        {!showSpinner &&
          <>
            <div className="row mb-4">
              <div className="col-md-12">
                <h4>Información</h4>
                <DataTable
                  columns={informacionColumns}
                  data={[listaDePrecios]}
                  paginationRowsPerPageOptions={[10, 20, 30, 50, 100]}
                  paginationComponentOptions={paginationComponentOptions}
                  pagination
                  highlightOnHover
                  pointerOnHover
                  striped
                  responsive
                  noHeader
                />
              </div>
            </div>
            <div className="row mb-4">
              <div className="col-md-12">
                <h4>Reglas Aplicadas</h4>
                <DataTable
                  columns={rulesColumns}
                  data={listaDePrecios.reglas}
                  paginationRowsPerPageOptions={[10, 20, 30, 50, 100]}
                  paginationComponentOptions={paginationComponentOptions}
                  pagination
                  highlightOnHover
                  pointerOnHover
                  striped
                  responsive
                  noHeader
                />
              </div>
            </div>
            
            <div className="row mb-2">
              <div className="col-md-12">
                <label>Filtros:</label>
              </div>
              <div className="col-md-2">
                <label>Número de lote:</label>
                <input type="text" className="form-control" placeholder='Rango ej: 1 - 5' onChange={(e) => setFilter('numeroLote', getRango(e.target.value))} />
              </div>
              <div className="col-md-2">
                <label>Número de Manzana:</label>
                <input type="text" className="form-control" placeholder='Rango ej: 1 - 5' onChange={(e) => setFilter('manzana', getRango(e.target.value))} />
              </div>
              <div className="col-md-2">
                <label>Superficie:</label>
                <input type="text" className="form-control" placeholder='Rango ej: 300.50 - 450' onChange={(e) => setFilter('superficie', getRango(e.target.value))} />
              </div>
              <div className="col-md-2">
                <label>Tipología:</label>
                <Select options={tipologias.map(tipologia => ({ value: tipologia._id, label: tipologia.tipo }))} onChange={e => setFilter('tipologia', e.value)} />
              </div>
            </div>


            <div className="row mb-4">
              <div className="col-md-12">
                <h4>Lotes</h4>
                <DataTable
                  columns={columnas}
                  data={lotesFiltrados}
                  paginationRowsPerPageOptions={[10, 20, 30, 50, 100]}
                  paginationComponentOptions={paginationComponentOptions}
                  pagination
                  highlightOnHover
                  pointerOnHover
                  striped
                  responsive
                  noHeader
                />
              </div>
            </div>

            <div className="row mb-4">
              <div className="col-md-10">
                <h4>Generar link de acceso único</h4>
                <div className="form-group">
                  <label>Usuario Aprobador</label>
                  <Select
                    options={usuariosAdmin.map(usuario => ({ value: usuario._id, label: `${usuario.apellido}, ${usuario.nombre}` }))}
                    onChange={e => setUsuarioAplicacion(e.value)}
                  />
                </div>
                <div className="form-group">
                  <button disabled={!usuarioAplicacion} className="btn btn-success" onClick={enviarLinkAcceso}>
                    Generar
                  </button>
                </div>
              </div>
            </div>

            <div className="row mb-4">
              <div className="col-md-12">
                <button className="btn btn-success float-right ml-3" onClick={aplicarListaDePrecios}
                  disabled={listaDePrecios.estado !== 'Pendiente'}
                >Aplicar</button>
                <button className="btn btn-danger float-right ml-3" onClick={deleteListaDePrecios}
                  disabled={listaDePrecios.estado !== 'Pendiente'}
                >Cancelar</button>
              </div>
            </div>
          </>
        }
      </div>
      <div className="card-footer"></div>
    </div>
  )
}

export default VerListaDePrecios