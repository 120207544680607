import React, { useContext, useEffect, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import clienteAxios from "../../config/axios";
import { CRMContext } from "../../context/CRMContext";
import Spinner from "../layout/Spinner";
import { useLocation } from "react-router-dom";
import jwt_decode from 'jwt-decode'

const Menu = (props) => {
  const [auth, guardarAuth] = useContext(CRMContext);
  const [menu, guardarMenu] = useState([]);
  const nombre = localStorage.getItem("nombre");
  const apellido = localStorage.getItem("apellido");
  const rol = localStorage.getItem("rol");

  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const urlToken = searchParams.get("token");

  if (urlToken) {
    const decodeToken = jwt_decode(urlToken);

    const { id, nombre, apellido, rol, inmobiliariaId, rolId } = decodeToken;

    localStorage.setItem("token", urlToken);
    localStorage.setItem("idUsuario", id);
    localStorage.setItem("nombre", nombre);
    localStorage.setItem("apellido", apellido);
    localStorage.setItem("rol", rol);
    localStorage.setItem("inmobiliariaId", inmobiliariaId._id);
    localStorage.setItem("rolId", rolId);
  }

  let token = auth.token || localStorage.getItem("token");

  const compare_name = (a, b) => {
    if (a.nombre.toLowerCase() < b.nombre.toLowerCase()) {
      return -1;
    }
    if (a.nombre.toLowerCase() > b.nombre.toLowerCase()) {
      return 1;
    }
    return 0;
  }

  const consultarAPI = async () => {
    try {
      const menuDB = await clienteAxios.get(`/menu/${rol}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      let { menu } = menuDB.data;
      menu.forEach(men => {
        men.submenus.sort(compare_name);
      });

      guardarMenu(menu);
    } catch (error) {
      if (error.response?.status === 500) {
        localStorage.clear();
        props.history.push("/login");
      }
      console.log(error)
    }
  };

  useEffect(() => {
    if (token) {
      consultarAPI()
    };
  }, [rol, token, props.history]);

  if (!token) {
    return (
      <>
        <aside className="main-sidebar logo-login btn-success elevation-4">
          <img className="logo-login" src="/dist/img/AdminLTELogo.png" alt="" />
        </aside>
      </>
    );
  }

  if (menu === undefined) {
    return <Spinner />;
  }


  return (
    <aside className="main-sidebar sidebar-dark-primary elevation-4">
      <Link to="/" className="brand-link">
        <img
          src="/dist/img/AdminLTELogo.png"
          alt="Life Desarrollos Logo"
          className="brand-image "
          style={{ opacity: ".8" }}
        />
        <span className="brand-text font-weight-light">.</span>
      </Link>
      <div className="sidebar">
        <div className="user-panel mt-3 pb-3 mb-3 d-flex">
          {/* <div className="image">
            <img
              src="/dist/img/user2-160x160.jpg"
              className="img-circle elevation-2"
              alt="User"
            />
          </div> */}
          <div className="info">
            <Link to="#" className="d-block">{`${nombre} ${apellido}`}</Link>
          </div>
        </div>
        <nav className="mt-2">
          <ul
            className="nav nav-pills nav-sidebar flex-column"
            data-widget="treeview"
            role="menu"
            data-accordion="false"
          >
            <li className="nav-header">Categorías</li>

            {menu.map((men) => (
              <li
                key={men.nombre}
                className="nav-item menu-is-opening menu-open"
              >
                <Link to="#" className="nav-link active">
                  <i className={men.clase} />
                  <p>
                    {men.nombre}
                    <i className="right fas fa-angle-left" />
                  </p>
                </Link>

                <ul className="nav nav-treeview">
                  {men.submenus.map((sub) => (
                    <li key={sub.url} className="nav-item" onClick={
                      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })}>
                      <Link to={sub.url} className="nav-link active">
                        <i className={sub.clase} />
                        <p>{sub.nombre}</p>
                      </Link>
                    </li>
                  ))}
                </ul>
              </li>
            ))}
          </ul>
        </nav>
      </div >
    </aside >
  );
};

export default withRouter(Menu);
