import React from 'react'

const Phone = (props) => {
  const { client } = props;
  const { telefono } = client;
  return (
    <div className='mb-4'>
      <div className="row">
        <label>Teléfono:</label>
      </div>
      <div className="row">
        <div className="col-md-6">
          <label>Teléfono Móvil</label>
          <input className="form-control" type="text" value={telefono?.movil || '-'} disabled/>
        </div>
        <div className="col-md-6">
          <label>Teléfono Fijo</label>
          <input className="form-control" type="text" value={telefono?.fijo || '-'} disabled/>
        </div>
      </div>
    </div>
  )
}

export default Phone