import { get } from "../../../../config/apiHelper";

export const getClientByCuix = async (cuix) => {
  try {
    const client = await get(`/clientes/cuitCuil/${cuix}`);
    return client;
  } catch (error) {
    console.error(error);
  }
}

export const getPersonaByCuix = async (cuix) => {
  try {
    const {data: persona} = await get(`/afip?id=${cuix}`);
    return persona.cliente;
  } catch (error) {
    console.error(error);
  }
}
