import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import { get, post } from "../../config/apiHelper";
import Spinner from "../layout/Spinner";
import Select from "react-select";
import InputRango from "./components/InputRango";

const ListadoPrecios = (props) => {
  const [desarrollos, setDesarrollos] = useState([]);
  const [lotes, setLotes] = useState([]);
  const [lotesOriginales, setLotesOriginales] = useState([]); // Para almacenar los precios originales
  const [selectedDesarrollo, setSelectedDesarrollo] = useState(null);
  const [tipologias, setTipologias] = useState([]);
  const [showSpinner, setShowSpinner] = useState(true);
  const [reglas, setReglas] = useState([]); // Para almacenar las reglas de modificación
  const [lotesFiltrados, setLotesFiltrados] = useState([]);
  const [filters, setFilters] = useState({});

  const paginationComponentOptions = {
    rowsPerPageText: "Filas por página",
    rangeSeparatorText: "de",
    selectAllRowsItem: true,
    selectAllRowsItemText: "Todos",
  };

  const getBarrios = async () => {
    try {
      const response = await get("/barrios?activo=true");
      setDesarrollos(response);
    } catch (error) {
      console.log(error);
    }
  };

  const getTipologias = async () => {
    try {
      const response = await get("/tipologias");
      setTipologias(response);
    } catch (error) {
      console.log(error);
    }
  };

  const getLotes = async (desarrolloId) => {

    try {
      setShowSpinner(true);
      const response = await get(`/lotes?barrio=${desarrolloId}&campos=numeroManzana,numeroLote,superficie,tipologia,estado,precioLista`);
      const lotesConDatos = response.data.map((lote) => ({
        ...lote,
        tipologia: lote.tipologia.tipo,
        estado: lote.estado.estado,
        precioNuevo: lote.precioLista,
      }));
      lotesConDatos.sort((a, b) => a.numeroLote - b.numeroLote);
      setLotes(lotesConDatos);
      setLotesFiltrados(lotesConDatos);
      setLotesOriginales(lotesConDatos); // Guardar los precios originales
    } catch (error) {
      console.log(error);
    } finally {
      setShowSpinner(false);
    }
  }

  const getRango = (string) => {
    if(typeof(string) !== 'string' || string.length === 0) {
      return null;
    }
    let [min, max] = string.split('-');
    if(!max) {
      max = min;
    }
    return [Number(min) , Number(max)];
  }

  const setFilter = (key, value) => {
    setFilters({
      ...filters,
      [key]: value
    });
  }

  useEffect(() => {
    const lotesFiltrados = lotes.filter( lote => {
      if(filters.numeroLote) {
        const rango = filters.numeroLote;
        if(lote.numeroLote < rango[0] || lote.numeroLote > rango[1]) {
          return false;
        }
      }
      if(filters.manzana) {
        const rango = filters.manzana;
        if(lote.numeroManzana < rango[0] || lote.numeroManzana > rango[1]) {
          return false;
        }
      }
      if(filters.superficie) {
        const rango = filters.superficie;
        if(lote.superficie < rango[0] || lote.superficie > rango[1]) {
          return false;
        }
      }
      if(filters.tipologia) {
        if(lote.tipologia.tipo !== filters.tipologia) {
          return false;
        }
      }
      return true;
    });
    setLotesFiltrados(lotesFiltrados);
  }, [filters, lotes]);


  useEffect(() => {
    try {
      setShowSpinner(true);
      Promise.all([getBarrios(), getTipologias()]).then(() => {
        setShowSpinner(false);
      });
    } catch (error) {
      console.log(error);
    }
  }, []);

  const handleDesarrolloChange = async (e) => {
    const desarrolloId = e.target.value;
    setSelectedDesarrollo(desarrolloId);
    await getLotes(desarrolloId);
  };


  const aplicarReglas = () => {
    setLotes(
      lotesOriginales.map((loteOriginal) => {
        let nuevoPrecio = loteOriginal.precioLista;
        if (loteOriginal.precioLista > 0) {
          reglas.forEach((regla) => {
            if (
              (!regla.tipologia && !regla.rangoManzana && !regla.rangoLotes) ||
              ((!regla.tipologia || loteOriginal.tipologia === regla.tipologia) &&
                (!regla.rangoManzana || (loteOriginal.numeroManzana >= regla.rangoManzana[0] && loteOriginal.numeroManzana <= regla.rangoManzana[1])) &&
                (!regla.rangoLotes || (loteOriginal.numeroLote >= regla.rangoLotes[0] && loteOriginal.numeroLote <= regla.rangoLotes[1])))
            ) {
              if (regla.porcentaje) {
                nuevoPrecio = loteOriginal.precioLista * (1 + regla.porcentaje / 100);
              } else if (regla.valorFijo) {
                nuevoPrecio = loteOriginal.precioLista + regla.valorFijo;
              } else if (regla.metroCuadrado) {
                nuevoPrecio = regla.metroCuadrado * loteOriginal.superficie;
              }
            }
          });
        }
        return { ...loteOriginal, precioNuevo: Math.round(nuevoPrecio * 100) / 100 };
      })
    );
  };

  const agregarRegla = (campo, valor) => {
    const nuevaRegla = { ...campo, ...valor };
    setReglas((prev) => [...prev, nuevaRegla]);
  };

  const eliminarRegla = (index) => {
    setReglas((prev) => {
      const nuevasReglas = prev.filter((_, i) => i !== index);
      setLotes(
        lotesOriginales.map((loteOriginal) => {
          let nuevoPrecio = loteOriginal.precioLista;
          nuevasReglas.forEach((regla) => {
            if (
              (!regla.tipologia || loteOriginal.tipologia === regla.tipologia) &&
              (!regla.rangoManzana || (loteOriginal.numeroManzana >= regla.rangoManzana[0] && loteOriginal.numeroManzana <= regla.rangoManzana[1]))
            ) {
              if (regla.porcentaje) {
                nuevoPrecio *= 1 + regla.porcentaje / 100;
              } else {
                nuevoPrecio += regla.valorFijo;
              }
            }
          });
          return { ...loteOriginal, precioNuevo: Math.round(nuevoPrecio) };
        })
      );
      return nuevasReglas;
    });
  };

  const handleInputChange = (index, field, value) => {
    setReglas((prev) => {
      const reglasActualizadas = [...prev];
      reglasActualizadas[index] = { ...reglasActualizadas[index], [field]: value };
      return reglasActualizadas;
    });
  };

  const columnas = [
    { name: "N° Lote", selector: (row) => row.numeroLote, sortable: true },
    { name: "N° Manzana", selector: (row) => row.numeroManzana, sortable: true },
    { name: "Superficie", selector: (row) => row.superficie, sortable: true },
    { name: "Estado", selector: (row) => row.estado, sortable: true },
    { name: "Tipología", selector: (row) => row.tipologia, sortable: true },
    {
      name: "Precio Lista",
      selector: (row) => `$${row.precioLista.toFixed(2)}`,
      sortable: true,
    },
    {
      name: "Precio Nuevo",
      cell: (row) => (
        <input
          type="number"
          className="form-control"
          value={row.precioNuevo}
          onChange={(e) => {
            const value = +e.target.value;
            console.log(value);
            setLotes((prev) => prev.map((lote) => (lote.numeroLote === row.numeroLote ? { ...lote, precioNuevo: value } : lote)));
          }}
        />
      ),
    },
    {
      name: "Variación",
      selector: (row) => `${((row.precioNuevo / row.precioLista - 1) * 100).toFixed(2)} %`,
      sortable: true,
    },
    {
      name: "m2 anterior",
      selector: (row) => `$ ${(row.precioLista / row.superficie).toFixed(2)}`,
      sortable: true,
    },
    {
      name: "m2 nuevo",
      selector: (row) => `$ ${(row.precioNuevo / row.superficie).toFixed(2)}`,
      sortable: true,
    },
  ];


  const enviar = async () => {
    try {
      const data = {
        lotes: lotes.map((lote) => ({
          lote: lote._id,
          precioAnterior: lote.precioLista,
          precioNuevo: lote.precioNuevo,
        })),
        reglas,
        barrio: selectedDesarrollo,
      }
      const response = await post('/api/listaDePrecios', data);
      props.history.push(`/listaDePrecios/${response._id}`);
    } catch (error) {
      console.log(error);
    }
  }


  return (
    <div className="card">
      <div className="card-header">
        <h3 className="card-title">Lista de Precios</h3>
        <button className="btn btn-primary float-right" onClick={props.history.goBack}>Volver</button>
      </div>
      <div className="card-body">
        <div className="row mb-4">
          <div className="col-md-6">
            <label htmlFor="desarrollo">Seleccionar Desarrollo</label>
            <select
              id="desarrollo"
              className="form-control"
              value={selectedDesarrollo || ""}
              onChange={handleDesarrolloChange}
            >
              <option value="">Seleccione</option>
              {desarrollos.map((desarrollo) => (
                <option key={desarrollo._id} value={desarrollo._id}>
                  {desarrollo.nombre}
                </option>
              ))}
            </select>
          </div>
        </div>

        {showSpinner ? <Spinner /> : (
          <>
            <div className="row mb-4">
              <div className="col-md-12">
                <h5>Reglas de Modificación</h5>
                {reglas.map((regla, index) => (
                  <div key={index} className="mb-2">
                    <div key={index} className="row align-items-center">
                      <div className="col-md-2">
                        <label>Tipologías</label>
                        <select
                          className="form-control"
                          value={regla.tipologia || ""}
                          onChange={(e) => handleInputChange(index, "tipologia", e.target.value)}
                        >
                          <option value="">Todas las tipologías</option>
                          {tipologias.map((tipologia) => (
                            <option key={tipologia.id} value={tipologia.tipo}>
                              {tipologia.tipo}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="col-md-2">
                        <label>Lotes</label>
                        <InputRango index={index} text={'Rango Lotes'} name="rangoLotes" handleInputChange={handleInputChange} />
                      </div>
                      <div className="col-md-2">
                        <label>Manzanas</label>
                        <InputRango index={index} text={'Rango Manzana'} name="rangoManzana" handleInputChange={handleInputChange} />
                      </div>
                      <div className="col-md-1">
                        <label>Valor %</label>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="% Incremento"
                          onChange={(e) => handleInputChange(index, "porcentaje", +e.target.value)}
                        />
                      </div>
                      <div className="col-md-1">
                        <label>Valor Fijo</label>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="$ Incremento"
                          onChange={(e) => handleInputChange(index, "valorFijo", +e.target.value)}
                        />
                      </div>
                      <div className="col-md-1">
                        <label>Valor m2</label>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="$ m2"
                          onChange={(e) => handleInputChange(index, "metroCuadrado", +e.target.value)}
                        />
                      </div>
                      <div className="col-md-1">
                        <button className="btn btn-danger" onClick={() => eliminarRegla(index)}>
                          Eliminar
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
                <button className="btn btn-primary" onClick={() => agregarRegla({})}>
                  Agregar Regla
                </button>
                <button className="btn btn-success ml-2" onClick={aplicarReglas}>
                  Aplicar Reglas
                </button>
              </div>
            </div>

            <div className="row mb-2">
              <div className="col-md-12">
                <label>Filtros:</label>
              </div>
              <div className="col-md-2">
                <label>Número de lote:</label>
                <input type="text" className="form-control" placeholder='Rango ej: 1 - 5' onChange={(e) => setFilter('numeroLote', getRango(e.target.value))} />
              </div>
              <div className="col-md-2">
                <label>Número de Manzana:</label>
                <input type="text" className="form-control" placeholder='Rango ej: 1 - 5' onChange={(e) => setFilter('manzana', getRango(e.target.value))} />
              </div>
              <div className="col-md-2">
                <label>Superficie:</label>
                <input type="text" className="form-control" placeholder='Rango ej: 300.50 - 450' onChange={(e) => setFilter('superficie', getRango(e.target.value))} />
              </div>
              <div className="col-md-2">
                <label>Tipología:</label>
                <Select options={tipologias.map(tipologia => ({ value: tipologia._id, label: tipologia.tipo }))} onChange={e => setFilter('tipologia', e.value)} />
              </div>
            </div>

            <div className="row mb-4">
              <div className="col-md-12">
                <DataTable
                  title="Lotes"
                  columns={columnas}
                  pagination
                  paginationRowsPerPageOptions={[10, 20, 30, 50, 100]}
                  paginationComponentOptions={paginationComponentOptions}
                  paginationTotalRows={lotesFiltrados.length}
                  data={lotesFiltrados}
                  highlightOnHover

                />
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <button className="btn btn-success float-right" onClick={enviar}>Enviar</button>
              </div>
            </div>
          </>
        )}

      </div>
    </div>
  );
};

export default ListadoPrecios;
