import React, { useEffect, useState } from 'react'
import Select from "react-select";
import FormularioVehiculo from "./components/FormularioVehiculo";
import FormularioInmueble from "./components/FormularioInmueble";
import { get } from '../../config/apiHelper';

function AgregarBien(props) {

  const [tipo, setTipo] = useState('');
  const [desarrollos, setDesarrollos] = useState([]);

  const getDesarrollos = async () => {
    try {
      const response = await get('/barrios?activo=true');
      setDesarrollos(response.map((desarrollo) => {
        return {
          label: desarrollo.nombre,
          value: desarrollo._id
        }
      }));
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    getDesarrollos();
  }, [])


  return (
    <div className="card">
      <div className="card-header">
        <div className="row">
          <div className="col-md-10">
            <h3 className="card-title mt-2">Nuevo Bien</h3>
          </div>
          <div className="col-md-2">
            <button className="btn btn-primary float-right" onClick={props.history.goBack}>Volver</button>
          </div>
        </div>
      </div>

      <div className="card-body">
        <div className="row">
          <div className="col-md-4">
            <div className="form-group">
              <label>Tipo:</label>
              <Select
                options={[
                  {
                    label: "Vehículo",
                    value: 'vehiculo'
                  },
                  {
                    label: "Inmueble",
                    value: 'inmueble'
                  },
                ]}
                onChange={(e) => setTipo(e.value)}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            {tipo === 'vehiculo' ? <FormularioVehiculo desarrollos={desarrollos} /> : null}
            {tipo === 'inmueble' ? <FormularioInmueble desarrollos={desarrollos} /> : null}
          </div>
        </div>
      </div>
    </div>
  )
}

export default AgregarBien