import React, { useState } from "react";

// Generador de datos aleatorios
const generarDatos = () => {
  const nombres = ["Juan", "María", "Carlos", "Ana", "Luis", "Sofía"];
  const apellidos = ["Pérez", "González", "Rodríguez", "López", "Fernández"];
  const desarrollos = ["Desarrollo 1", "Desarrollo 2", "Desarrollo 3"];
  const lotes = ["Lote 1", "Lote 2", "Lote 3", "Lote 4"];
  const tiposCesion = ["Entre Partes", "Formal"];
  const estados = ["Declarada", "No Declarada"];

  return Array.from({ length: 30 }, () => ({
    id: Math.random().toString(36).substr(2, 9),
    desarrollo: desarrollos[Math.floor(Math.random() * desarrollos.length)],
    lote: lotes[Math.floor(Math.random() * lotes.length)],
    comprador: `${apellidos[Math.floor(Math.random() * apellidos.length)]}, ${nombres[Math.floor(Math.random() * nombres.length)]
      }`,
    vendedor: `${apellidos[Math.floor(Math.random() * apellidos.length)]}, ${nombres[Math.floor(Math.random() * nombres.length)]
      }`,
    tipoCesion: tiposCesion[Math.floor(Math.random() * tiposCesion.length)],
    estado: estados[Math.floor(Math.random() * estados.length)],
  }));
};

const ListadoCesiones = ({ onVerDetalle }) => {
  const [cesiones] = useState(generarDatos());
  const [filtros, setFiltros] = useState({
    desarrollo: "",
    tipoCesion: "",
    estado: "",
    busqueda: "",
  });
  const [pagina, setPagina] = useState(1);
  const cesionesPorPagina = 5;

  // Manejo de filtros
  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFiltros({ ...filtros, [name]: value });
    setPagina(1); // Reinicia a la primera página al cambiar filtros
  };

  // Filtrar y buscar
  const cesionesFiltradas = cesiones.filter((cesion) =>
    Object.entries(filtros).every(([clave, valor]) => {
      if (clave === "busqueda") {
        return Object.values(cesion)
          .join(" ")
          .toLowerCase()
          .includes(valor.toLowerCase());
      }
      return valor === "" || cesion[clave]?.includes(valor);
    })
  );

  // Paginación
  const totalPaginas = Math.ceil(cesionesFiltradas.length / cesionesPorPagina);
  const inicio = (pagina - 1) * cesionesPorPagina;
  const cesionesPaginadas = cesionesFiltradas.slice(
    inicio,
    inicio + cesionesPorPagina
  );

  return (
    <div className="card">
      <div className="card-header">
        <h3 className="card-title">Listado de Cesiones</h3>
      </div>
      <div className="card-body">
        {/* Filtros */}
        <div className="row mb-3">
          <div className="col-md-3">
            <label htmlFor="desarrollo">Desarrollo</label>
            <select
              className="form-control"
              id="desarrollo"
              name="desarrollo"
              value={filtros.desarrollo}
              onChange={handleFilterChange}
            >
              <option value="">Todos</option>
              <option value="Desarrollo 1">Desarrollo 1</option>
              <option value="Desarrollo 2">Desarrollo 2</option>
              <option value="Desarrollo 3">Desarrollo 3</option>
            </select>
          </div>
          <div className="col-md-3">
            <label htmlFor="tipoCesion">Tipo de Cesión</label>
            <select
              className="form-control"
              id="tipoCesion"
              name="tipoCesion"
              value={filtros.tipoCesion}
              onChange={handleFilterChange}
            >
              <option value="">Todos</option>
              <option value="Entre Partes">Entre Partes</option>
              <option value="Formal">Formal</option>
            </select>
          </div>
          <div className="col-md-3">
            <label htmlFor="estado">Estado</label>
            <select
              className="form-control"
              id="estado"
              name="estado"
              value={filtros.estado}
              onChange={handleFilterChange}
            >
              <option value="">Todos</option>
              <option value="Declarada">Declarada</option>
              <option value="No Declarada">No Declarada</option>
            </select>
          </div>
          <div className="col-md-3">
            <label htmlFor="busqueda">Buscar</label>
            <input
              type="text"
              className="form-control"
              id="busqueda"
              name="busqueda"
              placeholder="Buscar por texto..."
              value={filtros.busqueda}
              onChange={handleFilterChange}
            />
          </div>
        </div>

        {/* Tabla */}
        <table className="table table-bordered table-striped">
          <thead>
            <tr>
              <th>ID</th>
              <th>Desarrollo</th>
              <th>Lote</th>
              <th>Comprador</th>
              <th>Vendedor</th>
              <th>Tipo de Cesión</th>
              <th>Estado</th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {cesionesPaginadas.length > 0 ? (
              cesionesPaginadas.map((cesion) => (
                <tr key={cesion.id}>
                  <td>{cesion.id}</td>
                  <td>{cesion.desarrollo}</td>
                  <td>{cesion.lote}</td>
                  <td>{cesion.comprador}</td>
                  <td>{cesion.vendedor}</td>
                  <td>{cesion.tipoCesion}</td>
                  <td>{cesion.estado}</td>
                  <td>
                    <button
                      className="btn btn-primary btn-sm"
                      onClick={() => onVerDetalle(cesion)}
                    >
                      Ver
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="8" className="text-center">
                  No se encontraron resultados.
                </td>
              </tr>
            )}
          </tbody>
        </table>

        {/* Paginación */}
        <div className="d-flex justify-content-between align-items-center mt-3">
          <button
            className="btn btn-secondary btn-sm"
            disabled={pagina === 1}
            onClick={() => setPagina(pagina - 1)}
          >
            Anterior
          </button>
          <span>
            Página {pagina} de {totalPaginas}
          </span>
          <button
            className="btn btn-secondary btn-sm"
            disabled={pagina === totalPaginas}
            onClick={() => setPagina(pagina + 1)}
          >
            Siguiente
          </button>
        </div>
      </div>
    </div>
  );
};

export default ListadoCesiones;
