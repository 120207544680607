import React from 'react'

const NameFisicas = (props) => {
  const { client } = props;
  const { nombre } = client;
  return (
    <div className='mb-4'>
    <div className="row">
      <div className="col">
        <label>Nombre:</label>
      </div>
    </div>
      <div className="row">
        <div className="col-md-4">
          <label>Nombre</label>
          <input 
            type="text"
            className="form-control"
            value={nombre?.nombre || '-'}  
            disabled
          />
        </div>
        <div className="col-md-4">
          <label>Apellido</label>
          <input 
            type="text"
            className="form-control"
            value={nombre?.apellido || '-'}  
            disabled
          />
        </div>
        <div className="col-md-4">
          <label>Apellido Materno</label>
          <input 
            type="text"
            className="form-control"
            value={nombre?.apellidoMaterno || '-'}
            disabled
          />
        </div>
      </div>
    </div>
  )
}

export default NameFisicas