// import React, { useEffect, useState } from 'react'
// import { get } from '../../config/apiHelper';
// import GetOrCreateClient from '../clientes/components/GetOrCreateClient';


// const FormularioAlta = () => {
//   const [desarrollos, setDesarrollos] = useState([]);

//   const fetchDesarrollos = async () => {
//     try {
//       const response = await get('/barrios');
//       setDesarrollos(response);
//     } catch (error) {
//       console.error(error);
//     }
//   }

//   useEffect(() => {
//     fetchDesarrollos();
//   }, []);



//   return (
//     <>
//       <div class="card">
//         <div class="card-header">
//           <h3 class="card-title">Formulario de Cesión</h3>
//         </div>
//         <div class="card-body">
//           <form>
//             <div className="row">
//               {/* <!-- Selección de Desarrollo --> */}
//               <div class="form-group col-md-6">
//                 <label for="desarrollo">Seleccionar Desarrollo</label>
//                 <select class="form-control" id="desarrollo" name="desarrollo">
//                   <option value="">Seleccione un desarrollo</option>
//                   {desarrollos.map((desarrollo) => (
//                     <option value={desarrollo._id}>{desarrollo.nombre}</option>
//                   ))}
//                 </select>
//               </div>

//               {/* <!-- Selección de Lote --> */}
//               <div class="form-group col-md-6">
//                 <label for="lote">Seleccionar Lote</label>
//                 <select class="form-control" id="lote" name="lote">
//                   <option value="">Seleccione un lote</option>
//                   <option value="lote1">Lote 1</option>
//                   <option value="lote2">Lote 2</option>
//                 </select>
//               </div>
//             </div>
//             <div className="row">
//               {/* <!-- CUIT/CUIL Comprador --> */}
//               <div class="form-group col-md-12">
//                 <label for="cuitComprador">CUIT/CUIL del Comprador</label>
//                 <GetOrCreateClient operacion={{}} setOperacion={() =>{}} clienteFromBloqueo={false} />
//               </div>

//               {/* <!-- CUIT/CUIL Vendedor --> */}
//               <div class="form-group col-md-12">
//                 <label for="cuitVendedor">CUIT/CUIL del Vendedor</label>
//                 <GetOrCreateClient operacion={{}} setOperacion={() => { }} clienteFromBloqueo={false} />
//               </div>
//             </div>

//             {/* <!-- Tipo de Cesión --> */}
//             <div class="form-group">
//               <label>Tipo de Cesión</label>
//               <div class="form-check">
//                 <input class="form-check-input" type="radio" name="tipoCesion" id="cesionEntrePartes" value="entrePartes" />
//                 <label class="form-check-label" for="cesionEntrePartes">Entre Partes</label>
//               </div>
//               <div class="form-check">
//                 <input class="form-check-input" type="radio" name="tipoCesion" id="cesionFormal" value="formal" />
//                 <label class="form-check-label" for="cesionFormal">Formal</label>
//               </div>
//             </div>

//             {/* <!-- Declarada --> */}
//             <div class="form-group">
//               <label>Declarada</label>
//               <div class="form-check">
//                 <input class="form-check-input" type="checkbox" id="declarada" name="declarada" />
//                 <label class="form-check-label" for="declarada">¿Está declarada?</label>
//               </div>
//             </div>

//             {/* <!-- Comentarios --> */}
//             <div class="form-group">
//               <label for="comentarios">Comentarios</label>
//               <textarea class="form-control" id="comentarios" name="comentarios" rows="3" placeholder="Ingrese comentarios"></textarea>
//             </div>

//             {/* <!-- Adjuntar Archivos --> */}
//             <div class="form-group">
//               <label for="archivos">Adjuntar Archivos</label>
//               <input type="file" class="form-control" id="archivos" name="archivos" multiple />
//             </div>

//             {/* <!-- Botón de Enviar --> */}
//             <button type="submit" class="btn btn-primary">Enviar</button>
//           </form>
//         </div>
//       </div>

//     </>
//   )
// }

// export default FormularioAlta


import React, { useEffect, useState } from 'react';
import { get, post } from '../../config/apiHelper';
import SelectLotes from './SelectLotes';
import GetOrCreateClient from './GetOrCreateClient';
import Spinner from '../layout/Spinner';

const tiposCesiones = ['Acuerdo Formal', 'Acuerdo entre Partes', 'Cesión', 'Boleto Formal', 'Boleto entre Partes'];

const FormularioAlta = () => {
  const [desarrollos, setDesarrollos] = useState([]);
  const [selectedDesarrollo, setSelectedDesarrollo] = useState(null);
  const [lotesSeleccionados, setLotesSeleccionados] = useState([]);
  const [comprador, setComprador] = useState(null);
  const [vendedor, setVendedor] = useState(null);
  const [tipoCesion, setTipoCesion] = useState('');
  const [comentarios, setComentarios] = useState('');
  const [archivos, setArchivos] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const fetchDesarrollos = async () => {
    try {
      const response = await get('/barrios');
      setDesarrollos(response);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchDesarrollos();
  }, []);

  const handleFileChange = (e) => {
    setArchivos([...e.target.files]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    console.log(selectedDesarrollo, comprador, vendedor, tipoCesion);

    if (!selectedDesarrollo || !comprador || !vendedor || !tipoCesion) {
      alert('Por favor, complete todos los campos obligatorios.');
      return;
    }

    setIsSubmitting(true);

    const formData = new FormData();
    formData.append('desarrollo', selectedDesarrollo);
    formData.append('tipo', tipoCesion);
    formData.append('comprador', comprador);
    formData.append('vendedor', vendedor);
    formData.append('comentarios', comentarios);
    lotesSeleccionados.forEach((lote) => formData.append('lotes', lote.id));
    archivos.forEach((file) => formData.append('archivos', file));

    try {
      const response = await post('/api/notarial/cesiones', formData);
      alert('Cesión creada con éxito.');
      console.log(response);
      // Reset form state
      setSelectedDesarrollo(null);
      setLotesSeleccionados([]);
      setComprador(null);
      setVendedor(null);
      setTipoCesion('');
      setComentarios('');
      setArchivos([]);
    } catch (error) {
      console.error(error);
      alert('Error al crear la cesión.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="card">
      <div className="card-header">
        <h3 className="card-title">Formulario de Cesión</h3>
      </div>
      <div className="card-body">
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="form-group col-md-6">
              <label htmlFor="lotes">Seleccionar Lotes</label>
              <SelectLotes
                lotesFromBloqueo={lotesSeleccionados}
                setLotesSeleccionados={setSelectedDesarrollo}
              />
            </div>
          </div>
          <div className="row">
            <div className="form-group col-md-6">
              <label htmlFor="comprador">CUIT/CUIL del Comprador</label>
              <GetOrCreateClient setClientData={(value) => {console.log(value)}} />
            </div>

            <div className="form-group col-md-6">
              <label htmlFor="vendedor">CUIT/CUIL del Vendedor</label>
              <GetOrCreateClient setClientData={(value) => {console.log(value)}} />
            </div>
          </div>

          <div className="form-group">
            <label>Tipo de Cesión</label>
            <select
              className="form-control"
              value={tipoCesion}
              onChange={(e) => setTipoCesion(e.target.value)}
            >
              <option value="">Seleccione un tipo de cesión</option>
              {tiposCesiones.map((tipo) => (
                <option key={tipo} value={tipo}>
                  {tipo}
                </option>
              ))}
            </select>
          </div>

          <div className="form-group">
            <label htmlFor="comentarios">Comentarios</label>
            <textarea
              className="form-control"
              id="comentarios"
              rows="3"
              value={comentarios}
              onChange={(e) => setComentarios(e.target.value)}
            />
          </div>

          <div className="form-group">
            <label htmlFor="archivos">Adjuntar Archivos</label>
            <input
              type="file"
              className="form-control"
              id="archivos"
              multiple
              onChange={handleFileChange}
            />
          </div>

          <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
            {isSubmitting ? <Spinner /> : 'Enviar'}
          </button>
        </form>
      </div>
    </div>
  );
};

export default FormularioAlta;
