import React from 'react'

const Email = (props) => {
  const { client } = props;
  const { email } = client;
  return (
    <div className='mb-4'>
      <div className="row">
        <label>Email:</label>
      </div>
      <div className="row">
        <div className="col-md-6">
          <label>Email</label>
          <input className="form-control" type="text" value={email || '-'} disabled/>
        </div>
      </div>
    </div>
  )
}

export default Email