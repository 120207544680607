import React, { useContext, useEffect, useState } from 'react';
import clienteAxios from '../../config/axios';
import { CRMContext } from '../../context/CRMContext';
import Spinner from '../layout/Spinner';
import ExternalPropertyUtils from '../operaciones/nuevaOperacion/components/wizardFormaPago/utils/ExternalPropertyUtils';
import TablaCotizaciones from "../operaciones/components/TablaCotizaciones";
import FormularioVentaBien from './components/FormularioVentaBien';
import moment from "moment/moment";
import Swal from "sweetalert2";
import ArchivosAdjuntosBienes from "./components/ArchivosAdjuntosBienes";
import { Link } from "react-router-dom";

const moneyArFormatter = new Intl.NumberFormat('es-AR', {
  style: 'currency',
  currency: 'ARS',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0
});

const moneyUsFormatter = new Intl.NumberFormat("es-AR", {
  style: "currency",
  currency: "USD",
  currencyDisplay: "symbol",
  minimumFractionDigits: 0,
  maximumFractionDigits: 0
});

const formatCuilCuit = (original) => {
  if (!original) {
    return '';
  }
  if (!isNaN(original)) {
    original = original.toString();
  }
  const part1 = original.slice(0, 2);
  const part2 = original.slice(2, 10);
  const part3 = original.slice(10);
  return `${part1}-${part2}-${part3}`;
}

const GetVehiculo = ({ vehiculo }) => {

  return (
    <>
      <div className="card-body">
        <div className="row mb-3">
          <div className='col-md-4 form-group'>
            <label>Tipo</label>
            <p>{vehiculo.descripcion.vehiculo.type}</p>
          </div>

          <div className='col-md-4 form-group'>
            <label>Marca</label>
            <p>{vehiculo.descripcion.vehiculo.brand}</p>
          </div>

          <div className='col-md-4 form-group'>
            <label>Modelo</label>
            <p>{vehiculo.descripcion.vehiculo.model}</p>
          </div>

        </div>
        <div className="row mb-3">

          <div className='col-md-4 form-group'>
            <label>Versión</label>
            <p>{vehiculo.descripcion.vehiculo.version}</p>
          </div>

          <div className='col-md-4 form-group'>
            <label>Año</label>
            <p>{vehiculo.descripcion.vehiculo.year}</p>
          </div>

        </div>
        <TablaCotizaciones allReadOnly={true} showBoleto={true} boletoReadOnly={true} cotizaciones={vehiculo.descripcion.valores} setCotizaciones={() => { }} />
      </div>
      {/* </div> */}
    </>
  )
}

const GetInmueble = ({ inmueble, operacion, guardarOperacion, token, ...props }) => {

  const [provincias, setProvincias] = useState([]);
  const [localidades, setLocalidades] = useState([]);

  useEffect(() => {
    consultarAPIProvincias();
    consultarAPILocalidades(inmueble.descripcion.provincia);
  }, []);


  const consultarAPIProvincias = async () => {
    try {
      const response = await clienteAxios.get("/provinciasLocalidades/provincias", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const orderedStates = response.data.sort((a, b) => {
        return a.nombreProvincia > b.nombreProvincia ? 1 : -1
      });

      setProvincias(orderedStates.map(state => ({
        label: state.nombreProvincia,
        value: state.idProvincia,
      })))

    } catch (error) {
      if (error.response?.status === 500) {
        localStorage.clear();
        props.history.push("/login");
      }
      console.log(error);
    }
  };

  const consultarAPILocalidades = async (provincia) => {
    if (!provincia) {
      return;
    }
    try {
      const response = await clienteAxios.get(`/provinciasLocalidades/localidades/${provincia}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.data) {
        const orderedCities = response.data.sort((a, b) => {
          return a.nombreLocalidad > b.nombreLocalidad ? 1 : -1
        });

        setLocalidades(orderedCities.map(city => ({
          label: city.nombreLocalidad,
          value: city._id,
        })))
      }
    } catch (error) {
      if (error.response?.status === 500) {
        localStorage.clear();
        props.history.push("/login");
      }
      console.log(error);
    }
  };

  return (
    <>
      <div className="card-body">
        <>
          <div className="row mb-3">
            <label className="col-md-12">Ubicación</label>
            <div className='col-md-4 form-group'>
              <label>Provincia</label>
              <p>{provincias.length > 0 && ExternalPropertyUtils.getSelectedOptionExternal(provincias, inmueble.descripcion.provincia)?.label}</p>
            </div>
            {localidades && localidades.length > 0 &&
              <>
                <div className='col-md-4 form-group'>
                  <label>Localidad</label>
                  <p>{localidades.length > 0 && ExternalPropertyUtils.getSelectedOptionExternal(localidades, inmueble.descripcion.localidad)?.label}</p>
                </div>
              </>
            }
          </div>

          {inmueble.descripcion.direccion &&
            <div className="row mb-3">
              <div className="col-md-12">
                <label>Direccion</label>
                <p>{inmueble.descripcion.direccion}</p>
              </div>
            </div>
          }


          <div className="row mb-3">
            <label className="col-md-12">Características</label>
            <div className='col-md-3 form-group'>
              <label>Tipo de Propiedad</label>
              {/* {ExternalPropertyUtils.getSelectedOption('types', inmueble.descripcion?.type || null).label} */}
              <p>{inmueble.descripcion?.type || null}</p>
            </div>

            <div className='col-md-3 form-group'>
              <label>Ambientes</label>
              {/* <p>{ExternalPropertyUtils.getSelectedOption('ambientes', inmueble.descripcion?.ambientes).label}</p> */}
              <p>{inmueble.descripcion?.ambientes}</p>
            </div>

            <div className='col-md-3 form-group'>
              <label>Dormitorios</label>
              {/* <p>{ExternalPropertyUtils.getSelectedOption('dormitorios', inmueble.descripcion?.dormitorios).label}</p> */}
              <p>{inmueble.descripcion?.dormitorios}</p>
            </div>

            <div className='col-md-3 form-group'>
              <label>Baños</label>
              {/* <p>{ExternalPropertyUtils.getSelectedOption('banos', inmueble.descripcion?.banos).label}</p> */}
              <p>{inmueble.descripcion?.banos}</p>
            </div>

            <div className='col-md-3 form-group'>
              <label>Garage</label>
              {/* <p>{ExternalPropertyUtils.getSelectedOption('garage', inmueble.descripcion?.garage).label}</p> */}
              <p>{inmueble.descripcion?.garage}</p>
            </div>

          </div>

          <div className="row mb-3">
            <label className="col-md-12">Superficies</label>

            <div className="col-md-4 form-group">
              <label>Superficie Total en m2</label>
              <p>{inmueble.descripcion?.superficieTotal || null}</p>
            </div>

            <div className="col-md-4 form-group">
              <label>Superficie Cubierta en m2</label>
              <p>{inmueble.descripcion?.superficieCubierta || null}</p>
            </div>

            <div className="col-md-4 form-group">
              <label>Superficie Terreno en m2</label>
              <p>{inmueble.descripcion?.superficieTerreno || null}</p>
            </div>

          </div>


          <div className="row mb-3">
            <label className="col-md-12">Otros</label>
            <div className='col-md-4 form-group'>
              <label>Año de Construcción</label>
              <p>{inmueble.descripcion?.anoConstruccion}</p>
            </div>

            <div className='col-md-4 form-group'>
              <label>Moneda Expensas</label>
              <p>{inmueble.descripcion && inmueble.descripcion?.monedaExpensas ? ExternalPropertyUtils.getTipoMonedaByValue(inmueble.descripcion?.monedaExpensas)?.label : null}</p>
            </div>

            <div className="col-md-4 form-group">
              <label>Expensas</label>
              <p>{moneyArFormatter.format(inmueble.descripcion?.expensas || null)}</p>
            </div>
          </div>
          <div className="row mb-3">
            <div className='col-md-6 form-group'>
              <label>Condición</label>
              {/* <p>{ExternalPropertyUtils.getSelectedOption('condicion', inmueble.descripcion?.condicion).label}</p> */}
              <p>{inmueble.descripcion?.condicion}</p>
            </div>
          </div>

          <div className="row mb-3">
            <label className="col-md-12">Otras Características</label>

            <div className='col-md-12 form-group'>
              <label>Otros</label>
              <p>{ExternalPropertyUtils.getMultiSelectedOptions('otros', inmueble.descripcion?.otros || []).map(opt => opt.label).join(', ')}</p>
            </div>
          </div>



          <TablaCotizaciones allReadOnly={true} showBoleto={true} boletoReadOnly={true} cotizaciones={inmueble.descripcion.valores} setCotizaciones={() => { }} />
        </>
      </div>
      {/* </div> */}
    </>
  )
}

const Bien = (props) => {
  const [auth, guardarAuth] = useContext(CRMContext);
  let token = auth.token || localStorage.getItem("token");
  const { id } = props.match.params;
  const userId = localStorage.getItem('idUsuario');
  const [bien, setBien] = useState({});
  const [loading, setLoading] = useState(false);
  const [nuevoComentario, setNuevoComentario] = useState('');
  const [operacionId, setOperacionId] = useState(null);

  const consultarAPI = async () => {
    try {
      setLoading(true);
      const apiResponse = await clienteAxios.get(`/bienes/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`
        },
      });
      const bien = apiResponse.data
      setBien(bien);
      await consultarOperacion(bien.tipo);
    } catch (error) {
      if (error.response?.status === 500) {
        localStorage.clear();
        props.history.push("/login");
      }
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const consultarOperacion = async (tipoBien) => {
    try {
      const apiResponse = await clienteAxios.get(`/operaciones/${tipoBien}/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`
        },
      });
      const idOperacion = apiResponse.data[0]._id;
      setOperacionId(idOperacion);
    } catch (error) {
      if (error.response?.status === 500) {
        localStorage.clear();
        props.history.push("/login");
      }
      console.log(error);
    }
  }

  useEffect(() => {
    consultarAPI();
  }, []);

  const spliceComentario = (comentario) => {
    const lineas = comentario.split('\n');
    return lineas;
  }

  const agregarComentario = async () => {
    if (!bien.comentarios) {
      bien.comentarios = [];
    }
    const bienCopy = { ...bien };
    bienCopy.comentarios.push(
      {
        comentario: nuevoComentario,
        fecha: Date.now(),
        usuario: userId,
      }
    );
    setBien(bienCopy);
    setNuevoComentario('');
    try {
      const rta = await clienteAxios.put(`/bienes/${id}`, bien, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (rta.data.estado == "error") {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: rta.data.msg,
        });
      }
      consultarAPI();
    } catch (error) {
      console.log(error);
      if (error.response?.status === 500) {
        localStorage.clear();
        props.history.push("/login");
      }
    }
  }



  if (loading) {
    return <Spinner />
  }

  return (
    <>
      <div className="card">
        <div className="card-header">
          <h3 className="card-title mt-2">BIEN</h3>
          <button className="btn btn-primary float-right ml-3" onClick={props.history.goBack}>Volver</button>
          <Link to={`/operaciones/${operacionId}`}><button disabled={!operacionId} className="btn btn-success float-right ml-3">Ir a la Operación</button></Link>
        </div>
        <div className="card-body">
          <div className="row mb-3">
            <div className="col">
              <label className='card-title mt-2 ml-3'>ESTADO: {bien?.estado || ''}</label>
            </div>
          </div>
          <div className="row ml-2 mb-3">
            <div className="col-md-4 form-group">
              <label>Proyecto Propietario:</label>
              <p>{bien.proyectoPropietario?.nombre ?? '-'}</p>
            </div>
          </div>
          {bien.cuitCuil ? <div className="row ml-2 mb-3">
            <div className="col-md-4 form-group">
              <label>Cuit / Cuil del Cliente:</label>
              <p>{bien.cuitCuil?.slice(0, 2) + '-' + bien.cuitCuil?.slice(2, 10) + '-' + bien.cuitCuil.slice(-1) ?? '-'}</p>
            </div>
          </div> : null}
          <div className="row mb-3">
            {bien && bien.tipo === "vehiculo" &&
              <GetVehiculo vehiculo={bien} token={token} />
            }
            {bien && bien.tipo === 'inmueble' &&
              <GetInmueble inmueble={bien} token={token} />
            }
          </div>

          <div className="row mt-4 mb-4">
            <div className="col-md-12">
              <div className="card-header">
                <h3 className="card-title">Comentarios:</h3>
              </div>
            </div>
            <div className="col-md-12 mt-3">
              {/* The time line */}
              <div className="timeline">
                {bien.comentarios?.length > 0 ? bien.comentarios.map((comentario, index) => {
                  return (
                    <div key={index}>
                      <i className="fas fa-envelope bg-blue" />
                      <div className="timeline-item">
                        <span className="time">
                          <i className="fas fa-clock" />{" "}
                          {moment(comentario.fecha)
                            //   .utcOffset(180)
                            .format("DD-MM-YYYY - HH:mm")}
                        </span>
                        <h3 className="timeline-header">
                          {`Usuario: ${comentario.usuario?.apellido || '< Sin Apellido >'}, ${comentario.usuario?.nombre || '< Sin Nombre >'}`} - {comentario.usuario?.inmobiliaria?.nombre || '< Sin Nombre Inmobiliaria>'}
                        </h3>
                        {spliceComentario(comentario.comentario).map((linea) => {
                          return <div className="timeline-body">{linea}</div>
                        })}
                      </div>
                    </div>
                  );
                }) : ''}
              </div>
              <div className="modal-body col-md-12">
                <p>Nuevo Comentario:</p>
                <textarea
                  className="p-4 w-100"
                  name="comentario"
                  id=""
                  cols="100"
                  rows="3"
                  onChange={(e) => setNuevoComentario(e.target.value)}
                  value={nuevoComentario}
                ></textarea>
              </div>
              <input className="btn btn-primary float-right" onClick={agregarComentario} type="button"
                value="Enviar Comentario" />
            </div>
          </div>
          <ArchivosAdjuntosBienes
            className="mb-4"
            bien={bien}
            consultarBien={consultarAPI}
          />
          {/* <DataTable columns={columns} data={data} pagination /> */}
          <FormularioVentaBien
            bien={bien}
            setBien={setBien}
          />
        </div>
      </div>
    </>
  );

}

export default Bien;