import { React, useContext, useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import moment from "moment";
import { SelectNacionalidad } from "./SelectNacionalidad";
import { SelectEstadoCivil } from "./SelectEstadoCivil";
import DatosConyuge from "./DatosConyuge";
import SelectNupcias from "./SelectNupcias";
import { CRMContext } from "../../../context/CRMContext";
import Spinner from "../../layout/Spinner";
import clienteAxios from "../../../config/axios";
import { withRouter } from "react-router-dom";

const GetOrCreateClient = ({ operacion, setOperacion, clienteFromBloqueo, ...props }) => {
  const [showError, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("Persona no encontrada");
  const [showSpinner, setShowSpinner] = useState(false);
  const [disabledInput, setIsDisabledInput] = useState(true);

  const [auth, guardarAuth] = useContext(CRMContext);
  const [client, setClient] = useState({
    nacionalidad: '',
    estadoCivil: {},
  });
  const [existentClient, setExistentClient] = useState(false);

  const [showSpinnerRepresentante, setShowSpinnerRepresentante] = useState(false);
  const [existentRepresentante, setExistentRepresentante] = useState(false);
  const [showErrorRepresentante, setErrorRepresentante] = useState(false);
  const [errorMessageRepresentante, setErrorMessageRepresentante] = useState("Persona no encontrada");

  let token = auth.token || localStorage.getItem("token");

  const handleClienteFromBloqueo = async () => {
    try {
      if (clienteFromBloqueo) {
        await getOrCreateClient({ value: clienteFromBloqueo });
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    handleClienteFromBloqueo();
  }, [clienteFromBloqueo]);

  useEffect(() => {
    setOperacion({
      ...operacion,
      cliente: client,
    })
  }, [client]);

  const actualizarStateV2 = (state) => {
    setClient(({
      ...client,
      ...state
    }))
  }

  const actualizarState = (e) => {
    setClient({
      ...client,
      [e.target.name]: e.target.value
    });
  }

  const actualizarRepresentante = (e) => {
    setClient({
      ...client,
      representante: {
        ...client.representante,
        [e.target.name]: e.target.value
      }
    });
  }


  const getOrCreateClient = async (e) => {
    setExistentClient(false);
    setError(false);
    setClient({});
    let clientFound = false;
    const taxId = e.value;
    if (taxId.length < 11) {
      return;
    }
    setIsDisabledInput(true);
    setShowSpinner(true);

    let apiClient = await getClientByTaxId(taxId);
    if (apiClient?.message && apiClient.message !== 'Client not found') {
      setErrorMessage(apiClient.message);
      setError(true);
      return;
    }
    if (apiClient && !apiClient.message) {
      apiClient.telefonoMovil = apiClient.telefono?.movil;
      apiClient.telefonoFijo = apiClient.telefono?.particular;
      setClient(apiClient);
      clientFound = true;
      setExistentClient(true);
    } else {
      apiClient = await getClientInfoFromAfip(taxId);
      if (apiClient && apiClient.hasOwnProperty('documentos')) {
        setClient(apiClient);
        clientFound = true;
      }
    }
    if (!clientFound) {
      const client = {};
      client.nombre = {};
      client.nombre.nombre = ' ';
      client.apellido = ' ';
      client.fechaNacimiento = Date.now();
      client.documentos = {};
      client.documentos.dni = ' ';
      client.domicilio = {};
      client.domicilio.provincia = ' ';
      setClient(client);
      setIsDisabledInput(false);
      if (!errorMessage) {
        setErrorMessage('Persona no encontrada');
      }
      setError(true);
    }
    setShowSpinner(false);

  }

  const getOrCreateRepresentante = async (e) => {
    setExistentRepresentante(false);
    setErrorRepresentante(false);
    console.log(e);
    setClient({
      ...client,
      representante: null,
    });
    let representanteFound = false;
    const taxId = e.value;
    if (taxId.length < 11) {
      return;
    }
    setShowSpinnerRepresentante(true);

    let apiRepresentante = await getClientByTaxId(taxId);

    if (apiRepresentante && apiRepresentante.message && apiRepresentante.message !== 'Client not found') {
      apiRepresentante.telefonoMovil = apiRepresentante.telefono?.movil;
      apiRepresentante.telefonoFijo = apiRepresentante.telefono?.particular;
      setClient({
        ...client,
        representante: apiRepresentante,
      });
      representanteFound = true;
      setExistentRepresentante(true);
    } else {
      apiRepresentante = await getClientInfoFromAfip(taxId);
      if (apiRepresentante && apiRepresentante.hasOwnProperty('documentos')) {
        setClient({
          ...client,
          representante: apiRepresentante,
        });
        representanteFound = true;
      }
    }
    if (!representanteFound) {
      setErrorMessageRepresentante('Persona no encontrada');
      setErrorRepresentante(true);
    }
    setShowSpinnerRepresentante(false);
  }

  const getClientByTaxId = async (taxId) => {
    try {
      const clientesConsulta = await clienteAxios.get(`/clientes/cuitCuil/${taxId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (clientesConsulta.data.hasOwnProperty('activo') || clientesConsulta.data.hasOwnProperty('message')) {
        return clientesConsulta.data;
      } else {
        return null;
      }
    } catch (error) {
      if (error.response?.status === 500) {
        localStorage.clear();
        props.history.push("/login");
      }
      console.log(error);
    }
  };

  const getClientInfoFromAfip = async (taxId) => {

    try {
      const rta = await clienteAxios.get(`/afip?id=${taxId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const { cliente: personaApi } = rta.data.data;
      if (rta.data.data.errorMessage) {
        setErrorMessage(rta.data.data.errorMessage);
        return null
      } else {
        return personaApi;
      }
    } catch (e) {
      console.log(e);
    }
  }

  console.log(client)


  return (
    <>
      <form>
        <div className="row">
          <div className="col-md-4 form-group">
            <label>Ingrese el CUIT / CUIL de la persona</label>
            <NumberFormat
              className="form-control"
              name="numeroCuilCuit"
              placeholder="20-12345678-9"
              format="##-########-#"
              mask="_"
              required
              onValueChange={getOrCreateClient}
            />
            {showError &&
              <div className="alert alert-primary" role="alert">
                {errorMessage}
              </div>
            }
          </div>
        </div>
        {showSpinner &&
          <Spinner />
        }
        {(client && client.nombre && Object.keys(client.nombre).length > 0) &&
          <div className="row">
            <div className="col-md-4 form-group">
              <label>Nombre *</label>
              <input
                type="text"
                className="form-control"
                placeholder="Nombre"
                name="nombre"
                value={client?.nombre.nombre}
                onChange={(e) => {
                  const clientLocal = { ...client };
                  clientLocal.nombre.nombre = e.target.value
                  setClient(clientLocal);
                }
                }
                required
                disabled={disabledInput}
              />
            </div>


            <div className="col-md-4 form-group">
              <label>Apellido *</label>
              <input
                type="text"
                className="form-control"
                placeholder="Apellido"
                name="apellido"
                value={client?.nombre.apellido}
                onChange={(e) => {
                  const clientLocal = { ...client };
                  clientLocal.nombre.apellido = e.target.value
                  setClient(clientLocal);
                }
                }
                required
                disabled={disabledInput}
              />
            </div>

            {client.fechaNacimiento &&
              <div className="col-md-4 form-group">
                <label>Fecha de Nacimiento *</label>
                <input
                  type="date"
                  className="form-control"
                  placeholder="Fecha de Nacimiento"
                  name="fechaNacimiento"
                  onChange={(e) => {
                    const clientLocal = { ...client };
                    clientLocal.fechaNacimiento = e.target.value
                    setClient(clientLocal);
                  }
                  }
                  value={moment(client?.fechaNacimiento)
                    .utcOffset(180)
                    .format("YYYY-MM-DD")}
                  required
                  disabled={disabledInput}
                />
              </div>
            }
          </div>
        }
        <div className="row">
          {client.razonSocial &&
            <>
              <div className="col-md-9 form-group">
                <label>Razón Social *</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Razón Social"
                  name="nombre"
                  value={client?.razonSocial}
                  disabled
                />
              </div>
            </>
          }
          {(client && client.nombre && Object.keys(client.nombre).length > 0) &&
            <div className="col-md-4 form-group">
              <label>CUIL</label>
              <NumberFormat
                className="form-control"
                name="dni"
                value={client?.documentos?.cuitCuil ?? ' '}
                onValueChange={(e) => {
                  const clientLocal = { ...client };
                  clientLocal.documentos.cuitCuil = e.value
                  setClient(clientLocal);
                }
                }
                placeholder="20-36543456-9"
                format="##-########-#"
                mask="_"
                required
                disabled={disabledInput}
              />
            </div>
          }
        </div>


        <div className="row">
          {client.documentos &&
            <>
              <div className="col-md-3 form-group">
                <label>Tipo: *</label>

                <input
                  type="text"
                  className="form-control"
                  placeholder="Tipo DNI"
                  name="dniTipo"
                  value={client?.documentos.dniTipo}
                  required
                  onChange={(e) => {
                    const clientLocal = { ...client };
                    clientLocal.documentos.dniTipo = e.target.value
                    setClient(clientLocal);
                  }
                  }
                  disabled={disabledInput}
                />
              </div>
              <div className="col-md-3 form-group">
                <label>Documento *</label>
                <NumberFormat
                  className="form-control"
                  name="dni"
                  value={client?.documentos.dni}
                  placeholder="20.123.456"
                  format="##.###.###"
                  mask="_"
                  required
                  onValueChange={(e) => {
                    const clientLocal = { ...client };
                    clientLocal.documentos.dni = e.value
                    setClient(clientLocal);
                  }
                  }
                  disabled={disabledInput}
                />
              </div>
            </>
          }

        </div>

        {client.domicilio &&
          <>
            <div className="form-group">
              <label>Domicilio</label>
            </div>

            <div className="row">
              <div className="col-md-5 form-group">
                <label>Provincia *</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Provincia"
                  name="provincia"
                  value={client.domicilio.provincia}
                  onChange={(e) => {
                    const clientLocal = { ...client };
                    clientLocal.domicilio.provincia = e.target.value
                    setClient(clientLocal);
                  }
                  }
                  disabled={disabledInput}
                />
              </div>

              <div className="col-md-5 form-group">
                <label>Municipio *</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Municipio"
                  name="municipio"
                  onChange={(e) => {
                    const clientLocal = { ...client };
                    clientLocal.domicilio.municipio = e.target.value
                    setClient(clientLocal);
                  }
                  }
                  value={client.domicilio.municipio}
                  disabled={disabledInput}
                />
              </div>
              <div className="col-md-2 form-group">
                <label>CP</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="CP"
                  name="cp"
                  value={client?.domicilio.cp}
                  onChange={(e) => {
                    const clientLocal = { ...client };
                    clientLocal.domicilio.cp = e.target.value
                    setClient(clientLocal);
                  }
                  }
                  required
                  disabled={disabledInput}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-md-6 form-group">
                <label>Calle *</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Calle"
                  name="calle"
                  value={client?.domicilio.calle}
                  onChange={(e) => {
                    const clientLocal = { ...client };
                    clientLocal.domicilio.calle = e.target.value
                    setClient(clientLocal);
                  }
                  }
                  required
                  disabled={disabledInput}
                />
              </div>

              <div className="col-md-3 form-group">
                <label>Altura *</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Altura"
                  name="altura"
                  value={client?.domicilio.altura}
                  onChange={(e) => {
                    const clientLocal = { ...client };
                    clientLocal.domicilio.altura = e.target.value
                    setClient(clientLocal);
                  }
                  }
                  required
                  disabled={disabledInput}
                />
              </div>
              <div className="col-md-3 form-group">
                <label>Piso / Depto</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Piso / Depto"
                  name="pisoDepto"
                  value={client?.domicilio.pisoDepto}
                  onChange={(e) => {
                    const clientLocal = { ...client };
                    clientLocal.domicilio.pisoDepto = e.target.value
                    setClient(clientLocal);
                  }
                  }
                  disabled={disabledInput}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-md-4 form-group">
                <label>Teléfono Móvil * (Sin 0 y sin 15)</label>
                <NumberFormat
                  className="form-control"
                  placeholder="__________"
                  name="telefonoMovil"
                  format="##########"
                  mask="_"
                  onChange={actualizarState}
                  value={client?.telefonoMovil || ''}
                  required
                  readOnly={existentClient}
                />
              </div>
              <div className="col-md-4 form-group">
                <label>Teléfono Fijo (Sin 0)</label>
                <NumberFormat
                  className="form-control"
                  name="telefonoFijo"
                  format="##########"
                  placeholder="__________"
                  mask="_"
                  onChange={actualizarState}
                  value={client?.telefonoFijo || ''}
                  readOnly={existentClient}
                />
              </div>
              {client && client.nombre &&
                <div className="col-md-4 form-group">
                  <label>Apellido Materno *</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Apellido Materno"
                    name="apellidoMaterno"
                    onChange={actualizarState}
                    value={client?.nombre.apellidoMaterno}
                    disabled={existentClient}
                  />

                </div>
              }
            </div>

            <div className="row">
              <div className="col-md-8 form-group">
                <label>Email *</label>
                <input
                  type="email"
                  className="form-control"
                  placeholder="Email"
                  name="email"
                  onChange={actualizarState}
                  value={client?.email}
                  required
                  disabled={existentClient}
                />
              </div>
              {(client.nombre || client.apellido) &&
                <SelectNacionalidad
                  actualizarState={actualizarState}
                  cliente={client}
                  token={token}
                  required
                  disabled={existentClient}
                />
              }
              {client.fechaContratoSocial &&
                <div className="col-md-4 form-group">
                  <label>Alta de Contrato Social *</label>
                  <input
                    type="date"
                    className="form-control"
                    placeholder="Alta de Contrato Social"
                    name="fechaAlta"
                    value={moment(client?.fechaContratoSocial)
                      .utcOffset(180)
                      .format("YYYY-MM-DD")}
                    disabled
                  />
                </div>
              }
            </div>
          </>
        }
        {client.razonSocial &&
          <>
            <div className="row">
              <label>
                Información del representante:
              </label>
            </div>
            <div className="row">
              <div className="col-md-4 form-group">
                <label>Ingrese CUIL del representante:</label>
                <NumberFormat
                  className="form-control"
                  name="numeroCuilCuit"
                  placeholder="20-12345678-9"
                  format="##-########-#"
                  mask="_"
                  required
                  onValueChange={getOrCreateRepresentante}
                />
                {showErrorRepresentante &&
                  <div className="alert alert-primary" role="alert">
                    {errorMessageRepresentante}
                  </div>
                }
              </div>
            </div>
            {showSpinnerRepresentante &&
              <Spinner />
            }
          </>
        }
        {(client.representante?.nombre || client.representante?.apellido) &&
          <div className="row">
            <div className="col-md-4 form-group">
              <label>Nombre *</label>
              <input
                type="text"
                className="form-control"
                placeholder="Nombre"
                name="nombre"
                value={client.representante?.nombre.nombre}
                required
                disabled
              />
            </div>


            <div className="col-md-4 form-group">
              <label>Apellido *</label>
              <input
                type="text"
                className="form-control"
                placeholder="Apellido"
                name="apellido"
                value={client.representante?.nombre.apellido}
                required
                disabled
              />
            </div>

            {client.representante?.fechaNacimiento &&
              <div className="col-md-4 form-group">
                <label>Fecha de Nacimiento *</label>
                <input
                  type="date"
                  className="form-control"
                  placeholder="Fecha de Nacimiento"
                  name="fechaNacimiento"
                  value={moment(client.representante?.fechaNacimiento)
                    .utcOffset(180)
                    .format("YYYY-MM-DD")}
                  required
                  disabled
                />
              </div>
            }
          </div>
        }
        {client.representante?.domicilio &&
          <>
            <div className="form-group">
              <label>Domicilio</label>
            </div>

            <div className="row">
              <div className="col-md-5 form-group">
                <label>Provincia *</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Provincia"
                  name="provincia"
                  value={client.representante?.domicilio.provincia}
                  disabled={disabledInput}
                />
              </div>

              <div className="col-md-5 form-group">
                <label>Municipio *</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Municipio"
                  name="municipio"
                  value={client.representante?.domicilio.municipio}
                  disabled={disabledInput}
                />
              </div>
              <div className="col-md-2 form-group">
                <label>Código Postal</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="CP"
                  name="cp"
                  value={client.representante?.domicilio.cp}
                  required
                  disabled={disabledInput}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-md-6 form-group">
                <label>Calle *</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Calle"
                  name="calle"
                  value={client.representante?.domicilio.calle}
                  required
                  disabled={disabledInput}
                />
              </div>

              <div className="col-md-3 form-group">
                <label>Altura *</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Altura"
                  name="altura"
                  value={client.representante?.domicilio.altura}
                  required
                  disabled={disabledInput}
                />
              </div>
              <div className="col-md-3 form-group">
                <label>Piso / Depto</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Piso / Depto"
                  name="pisoDepto"
                  value={client.representante?.domicilio.pisoDepto}
                  disabled={disabledInput}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-md-4 form-group">
                <label>Teléfono Móvil * (Sin 0 y sin 15)</label>
                <NumberFormat
                  className="form-control"
                  placeholder="__________"
                  name="telefonoMovil"
                  format="##########"
                  mask="_"
                  onChange={actualizarRepresentante}
                  value={client?.representante.telefonoMovil || ''}
                  required
                  readOnly={existentClient}
                />
              </div>
              <div className="col-md-4 form-group">
                <label>Teléfono Fijo (Sin 0)</label>
                <NumberFormat
                  className="form-control"
                  name="telefonoFijo"
                  format="##########"
                  placeholder="__________"
                  mask="_"
                  onChange={actualizarRepresentante}
                  value={client?.representante.telefonoFijo || ''}
                  readOnly={existentClient}
                />
              </div>
              {client && client.nombre &&
                <div className="col-md-4 form-group">
                  <label>Apellido Materno *</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Apellido Materno"
                    name="apellidoMaterno"
                    onChange={actualizarRepresentante}
                    value={client?.representante.nombre.apellidoMaterno}
                    disabled={existentClient}
                  />

                </div>
              }
            </div>

            <div className="row">
              <div className="col-md-8 form-group">
                <label>Email *</label>
                <input
                  type="email"
                  className="form-control"
                  placeholder="Email"
                  name="email"
                  onChange={actualizarRepresentante}
                  value={client.representante?.email}
                  required
                  disabled={existentClient}
                />
              </div>
              {(client.representante?.nombre || client.representante?.apellido) &&
                <SelectNacionalidad
                  actualizarState={actualizarRepresentante}
                  cliente={client.representante}
                  token={token}
                  required
                  disabled={existentClient}
                />
              }
            </div>
          </>
        }
        {(client.nombre && (client.nombre.nombre || client.nombre.apellido)) &&
          <>
            <SelectEstadoCivil actualizarState={actualizarState} cliente={client} disabled={existentClient} />
            <SelectNupcias cliente={client} actualizarState={actualizarState} disabled={existentClient} />
            <DatosConyuge cliente={client} actualizarState={actualizarStateV2} disabled={existentClient} />
          </>
        }
      </form>
    </>
  )
    ;
};

export default withRouter(GetOrCreateClient);