import React from 'react'

const Address = (props) => {
  const { client } = props;
  const { domicilio } = client;
  return (
    <div className='mb-4'>
      <div className="row">
        <label>Domicilio:</label>
      </div>
      <div className="row">
        <div className="col-md-4">
          <label>Provincia</label>
          <input type="text"
            className="form-control"
            value={domicilio?.provincia || '-'}
            disabled
          />
        </div>
        <div className="col-md-4">
          <label>Municipio</label>
          <input type="text"
          className="form-control"
            value={domicilio?.municipio || '-'}
            disabled
          />
        </div>
        <div className="col-md-4">
          <label>Calle</label>
          <input type="text"
            className="form-control"
            value={domicilio?.calle || '-'}
            disabled
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-4">
          <label>Altura</label>
          <input type="text"
            className="form-control"
            value={domicilio?.altura || '-'}
            disabled
          />
        </div>
        <div className="col-md-4">
          <label>Pisto / Depto</label>
          <input type="text"
            className="form-control"
            value={domicilio?.pisoDepto || '-'}
            disabled
          />
        </div>
        <div className="col-md-4">
          <label>Código Postal</label>
          <input type="text"
            className="form-control"
            value={domicilio?.cp || '-'}
            disabled
          />
        </div>
      </div>
    </div>
  )
}

export default Address