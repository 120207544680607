// import React, { useEffect, useState, useContext } from "react";
// import moment from "moment";
// import { withRouter } from "react-router-dom";
// import { get } from "../../config/apiHelper";
// import Spinner from "../layout/Spinner";

// const FichaCliente = ({ match, history }) => {
//   const [cliente, setCliente] = useState({ loading: true });
//   const [operaciones, setOperaciones] = useState([]);

//   const { id } = match.params;

//   // Obtener los datos del cliente
//   const consultarAPICliente = async () => {
//     try {
//       const clienteDB = await get(`/clientes/${id}`);

//       setCliente({
//         loading: false,
//         ...clienteDB,
//       });
//     } catch (error) {
//       if (error.response?.status === 500) {
//         localStorage.clear();
//         history.push("/login");
//       }
//       console.error(error);
//     }
//   };

//   // Obtener las operaciones relacionadas con el cliente
//   const consultarAPIOperaciones = async () => {
//     try {
//       const operacionesDB = await get(`/operaciones/cliente/${id}`);

//       setOperaciones(operacionesDB);
//     } catch (error) {
//       if (error.response?.status === 500) {
//         localStorage.clear();
//         history.push("/login");
//       }
//       console.error(error);
//     }
//   };

//   useEffect(() => {
//     consultarAPICliente();
//     consultarAPIOperaciones();
//   }, []);

//   if (cliente.loading) {
//     return <Spinner />;
//   }

//   return (
//     <div className="card">
//       <div className="card-header">
//         <h3 className="card-title">Ficha del Cliente</h3>
//         <button
//           className="btn btn-secondary float-right"
//           onClick={() => history.goBack()}
//         >
//           Volver
//         </button>
//       </div>
//       <div className="card-body">
//         {/* Información del cliente */}
//         <div className="row">
//           <div className="col-md-6">
//             <p><strong>Nombre:</strong> {cliente.nombre || "N/A"}</p>
//             <p><strong>Apellido:</strong> {cliente.apellido || "N/A"}</p>
//             <p><strong>Razón Social:</strong> {cliente.razonSocial || "N/A"}</p>
//             <p><strong>CUIT / CUIL:</strong> {cliente.cuitCuil || "N/A"}</p>
//           </div>
//           <div className="col-md-6">
//             <p><strong>Domicilio:</strong></p>
//             <p>
//               {cliente.calle ? `${cliente.calle} ${cliente.altura || ""}, ` : ""}
//               {cliente.provincia || "N/A"}, {cliente.municipio || "N/A"}
//             </p>
//             <p><strong>Teléfono:</strong> {cliente.telefonoMovil || "N/A"}</p>
//             <p><strong>Email:</strong> {cliente.email || "N/A"}</p>
//           </div>
//         </div>

//         {/* Operaciones relacionadas */}
//         <div className="row mt-4">
//           <div className="col-md-12">
//             <h4>Operaciones Relacionadas:</h4>
//             {operaciones.length > 0 ? (
//               <table className="table table-bordered table-striped">
//                 <thead>
//                   <tr>
//                     <th>ID</th>
//                     <th>Fecha</th>
//                     <th>Tipo</th>
//                     <th>Estado</th>
//                   </tr>
//                 </thead>
//                 <tbody>
//                   {operaciones.map((operacion) => (
//                     <tr key={operacion.id}>
//                       <td>{operacion.id}</td>
//                       <td>
//                         {moment(operacion.fechaAlta).format("DD-MM-YYYY")}
//                       </td>
//                       <td>{operacion.tipo || "N/A"}</td>
//                       <td>{operacion.estado || "N/A"}</td>
//                     </tr>
//                   ))}
//                 </tbody>
//               </table>
//             ) : (
//               <p>No hay operaciones relacionadas.</p>
//             )}
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default withRouter(FichaCliente);
