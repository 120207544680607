import React, { useContext, useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import Select from "react-select";
import clienteAxios from "../../config/axios";
import Spinner from "../layout/Spinner";
import { CRMContext } from "../../context/CRMContext";

// Hook para manejar el token centralizado
const useAuthToken = () => {
  const [auth] = useContext(CRMContext);
  return auth?.token || localStorage.getItem("token");
};

// Hook para manejo de debounce
const useDebounce = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => setDebouncedValue(value), delay);
    return () => clearTimeout(handler);
  }, [value, delay]);

  return debouncedValue;
};

const GenericSelect = ({ options, value, onChange, label, disabled }) => (
  <div className="form-group">
    <label>{label}</label>
    <Select options={options} value={value} onChange={onChange} isDisabled={disabled} />
  </div>
);

const GetOrCreateClient = ({ setClientData }) => {
  const token = useAuthToken();

  const [client, setClient] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [cuitCuil, setCuitCuil] = useState("");
  const [estadoCivilOptions, setEstadoCivilOptions] = useState([]);
  const debouncedCuitCuil = useDebounce(cuitCuil, 500);

  useEffect(() => {
    if (debouncedCuitCuil && debouncedCuitCuil.length === 11) {
      fetchClient(debouncedCuitCuil);
    }
  }, [debouncedCuitCuil]);

  useEffect(() => {
    fetchEstadoCivilOptions();
  }, []);

  const fetchEstadoCivilOptions = async () => {
    try {
      const response = await clienteAxios.get("/estadoCivil", {
        headers: { Authorization: `Bearer ${token}` },
      });
      const options = response.data.map((estado) => ({
        value: estado._id,
        label: estado.nombre,
      }));
      setEstadoCivilOptions(options);
    } catch (err) {
      console.error("Error al cargar opciones de estado civil", err);
    }
  };

  const fetchClient = async (taxId) => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await clienteAxios.get(`/clientes/cuitCuil/${taxId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const clientData = response.data;
      if (clientData) {
        setClient(clientData);
        setClientData(clientData);
      } else {
        throw new Error("Cliente no encontrado");
      }
    } catch (err) {
      console.error("Cliente no encontrado en la base de datos. Intentando AFIP.");
      try {
        const afipResponse = await clienteAxios.get(`/afip/cuitCuil/${taxId}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        const afipData = afipResponse.data;
        setClient(afipData);
        setClientData(afipData);
      } catch (afipError) {
        console.error("Error al buscar en AFIP:", afipError);
        setError("No se encontró el cliente en la base de datos ni en AFIP.");
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleInputChange = (field, value) => {
    const updatedClient = { ...client, [field]: value };
    setClient(updatedClient);
    setClientData(updatedClient);
  };

  const renderFormFields = () => {
    if (!client) return null;

    return client.razonSocial ? (
      <div>
        <div className="form-group">
          <label>Razón Social</label>
          <input
            type="text"
            className="form-control"
            value={client.razonSocial || ""}
            onChange={(e) => handleInputChange("razonSocial", e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>Fecha de Contrato Social</label>
          <input
            type="date"
            className="form-control"
            value={client.fechaContratoSocial || ""}
            onChange={(e) => handleInputChange("fechaContratoSocial", e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>Correo Electrónico</label>
          <input
            type="email"
            className="form-control"
            value={client.email || ""}
            onChange={(e) => handleInputChange("email", e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>Teléfono</label>
          <input
            type="text"
            className="form-control"
            value={client.telefono?.movil || ""}
            onChange={(e) => handleInputChange("telefono.movil", e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>Domicilio</label>
          <input
            type="text"
            className="form-control"
            value={`${client.domicilio?.calle || ""} ${client.domicilio?.altura || ""}`}
            onChange={(e) => handleInputChange("domicilio", e.target.value)}
          />
        </div>
      </div>
    ) : (
      <div>
        <div className="form-group">
          <label>Nombre</label>
          <input
            type="text"
            className="form-control"
            value={client.nombre?.nombre || ""}
            onChange={(e) => handleInputChange("nombre.nombre", e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>Apellido</label>
          <input
            type="text"
            className="form-control"
            value={client.nombre?.apellido || ""}
            onChange={(e) => handleInputChange("nombre.apellido", e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>Correo Electrónico</label>
          <input
            type="email"
            className="form-control"
            value={client.email || ""}
            onChange={(e) => handleInputChange("email", e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>Teléfono</label>
          <input
            type="text"
            className="form-control"
            value={client.telefono?.movil || ""}
            onChange={(e) => handleInputChange("telefono.movil", e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>Fecha de Nacimiento</label>
          <input
            type="date"
            className="form-control"
            value={client.fechaNacimiento ? client.fechaNacimiento.split("T")[0] : ""}
            onChange={(e) => handleInputChange("fechaNacimiento", e.target.value)}
          />
        </div>
        <GenericSelect
          options={estadoCivilOptions}
          value={estadoCivilOptions.find((option) => option.value === client.estadoCivil?._id) || null}
          onChange={(selected) => handleInputChange("estadoCivil", selected?.value)}
          label="Estado Civil"
          disabled={false}
        />
        {client.estadoCivil?.conyuge && (
          <div className="form-group">
            <label>Datos del Cónyuge</label>
            <div>
              <label>Nombre</label>
              <input
                type="text"
                className="form-control"
                value={client.datosConyuge?.nombre || ""}
                onChange={(e) => handleInputChange("datosConyuge.nombre", e.target.value)}
              />
            </div>
            <div>
              <label>Apellido</label>
              <input
                type="text"
                className="form-control"
                value={client.datosConyuge?.apellido || ""}
                onChange={(e) => handleInputChange("datosConyuge.apellido", e.target.value)}
              />
            </div>
          </div>
        )}
        <div className="form-group">
          <label>Domicilio</label>
          <input
            type="text"
            className="form-control"
            value={`${client.domicilio?.calle || ""} ${client.domicilio?.altura || ""}`}
            onChange={(e) => handleInputChange("domicilio", e.target.value)}
          />
        </div>
      </div>
    );
  };

  return (
    <div>
      <div className="form-group">
        <label>Ingrese el CUIT/CUIL</label>
        <NumberFormat
          className="form-control"
          format="##-########-#"
          mask="_"
          placeholder="20-12345678-9"
          onValueChange={(values) => setCuitCuil(values.value)}
        />
      </div>

      {isLoading && <Spinner />}
      {error && <div className="alert alert-danger">{error}</div>}

      {renderFormFields()}
    </div>
  );
};

export default GetOrCreateClient;
