import React from 'react'

const EstadoCivil = (props) => {
  const { client } = props;
  const { estadoCivil, datosConyuge, nupcias } = client;
  return (
    <div className='mb-4'>
      <div className="row">
        <label>Estado Civil:</label>
      </div>
      <div className="row">
        <div className="col-md-4">
          <label>Estado Civil</label>
          <input className="form-control" type="text" value={estadoCivil?.nombre || '-'} disabled/>
        </div>
        <div className="col-md-4">
          <label>Cantidad de Nupcias</label>
          <input className="form-control" type="text" value={nupcias || '-'} disabled/>
        </div>
      </div>
      {estadoCivil?.conyuge ? 
        <>
          <div className="row">
            <label>Datos del Conyuge:</label>
          </div>
          <div className="row">
            <div className="col-md-4">
              <label>Nombre</label>
              <input className="form-control" type="text" value={datosConyuge?.nombre || '-'} disabled/>
            </div>
            <div className="col-md-4">
              <label>Apellido</label>
              <input className="form-control" type="text" value={datosConyuge?.apellido || '-'} disabled/>
            </div>
          </div>
        </>  :
        null
      }
    </div>
  )
}

export default EstadoCivil