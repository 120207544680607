import React from "react";

const DetalleCesion = ({ cesion, onVolver }) => {
  if (!cesion) {
    return <p>No se encontró la cesión.</p>;
  }

  return (
    <div className="card">
      <div className="card-header">
        <h3 className="card-title">Detalle de Cesión</h3>
        <button className="btn btn-secondary btn-sm float-right" onClick={onVolver}>
          Volver
        </button>
      </div>
      <div className="card-body">
        <p><strong>ID:</strong> {cesion.id}</p>
        <p><strong>Desarrollo:</strong> {cesion.desarrollo}</p>
        <p><strong>Lote:</strong> {cesion.lote}</p>
        <p><strong>Comprador:</strong> {cesion.comprador}</p>
        <p><strong>Vendedor:</strong> {cesion.vendedor}</p>
        <p><strong>Tipo de Cesión:</strong> {cesion.tipoCesion}</p>
        <p><strong>Estado:</strong> {cesion.estado}</p>
      </div>
    </div>
  );
};

export default DetalleCesion;
