import React from 'react'

const FechaNacimiento = (props) => {
  const { client } = props;
  const { fechaNacimiento, fechaContratoSocial } = client;

  return (
    <div className='mb-4'>
      <div className="row">
        {client?.razonSocial ? <label>Alta Contrato Social</label> : <label>Fecha de Nacimiento</label> }
      </div>
      <div className="row">
        <div className="col-md-6">
        {client?.razonSocial ? 
          <>
            <label>Alta Contrato Social:</label>
            <input className="form-control" type="text" value={new Date(fechaContratoSocial)?.toLocaleDateString() || '-'} disabled/>
          </> : 
          <>
            <label>Fecha de Nacimiento:</label>
            <input className="form-control" type="text" value={new Date(fechaNacimiento)?.toLocaleDateString() || '-'} disabled/>
          </>}
        </div>
      </div>
    </div>
  )
}

export default FechaNacimiento