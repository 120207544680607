import React from 'react'

const Nacionalidad = (props) => {
  const { client } = props;
  const { nacionalidad } = client;
  return (
    <div className='mb-4'>
      <div className="row">
        <label>Nacionalidad:</label>
      </div>
      <div className="row">
        <div className="col-md-4">
          <label>Nacionalidad</label>
          <input className="form-control" type="text" value={nacionalidad?.nombre || '-'} disabled/>
        </div>
      </div>
    </div>
  )
}

export default Nacionalidad