import React, { useContext, useEffect, useState } from 'react';
import Select from 'react-select';
import DataTable from 'react-data-table-component';
import Spinner from '../layout/Spinner';
import { CRMContext } from '../../context/CRMContext';
import clienteAxios from '../../config/axios';

const SelectLotes = ({ lotesFromBloqueo = [], setLotesSeleccionados }) => {
  const [auth] = useContext(CRMContext);
  const token = auth.token || localStorage.getItem('token');

  const [barrios, setBarrios] = useState([]);
  const [lotes, setLotes] = useState([]);
  const [selectedLotes, setSelectedLotes] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const currencyFormatter = new Intl.NumberFormat('es-AR', {
    style: 'currency',
    currency: 'ARS',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  const fetchBarrios = async () => {
    try {
      const response = await clienteAxios.get('/barrios?activo=true&campos=nombre,_id', {
        headers: { Authorization: `Bearer ${token}` },
      });
      setBarrios(response.data.map((barrio) => ({ label: barrio.nombre, value: barrio._id })));
    } catch (error) {
      console.error('Error fetching barrios:', error);
    }
  };

  const fetchLotes = async (barrioId) => {
    try {
      setIsLoading(true);
      const response = await clienteAxios.get(`/lotes?barrio=${barrioId}&estado=0&sort=numeroLote,1`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setLotes(
        response.data.data.map((lote) => ({
          value: lote._id,
          label: `Lote ${lote.numeroLote} - ${currencyFormatter.format(lote.precioLista)}`,
          details: lote,
        }))
      );
    } catch (error) {
      console.error('Error fetching lotes:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleBarrioChange = (selectedBarrio) => {
    fetchLotes(selectedBarrio.value);
  };

  const addLote = (selectedLote) => {
    if (!selectedLotes.find((lote) => lote.value === selectedLote.value)) {
      const newSelection = [...selectedLotes, selectedLote];
      setSelectedLotes(newSelection);
      setLotesSeleccionados(newSelection);
    }
  };

  const removeLote = (loteId) => {
    const updatedLotes = selectedLotes.filter((lote) => lote.value !== loteId);
    setSelectedLotes(updatedLotes);
    setLotesSeleccionados(updatedLotes);
  };

  useEffect(() => {
    fetchBarrios();
    if (lotesFromBloqueo.length) {
      setSelectedLotes(lotesFromBloqueo);
    }
  }, [lotesFromBloqueo]);

  const columns = [
    {
      name: 'Lote',
      selector: (row) => row.details.numeroLote,
      sortable: true,
    },
    {
      name: 'Precio',
      selector: (row) => currencyFormatter.format(row.details.precioLista),
      sortable: true,
    },
    {
      name: 'Eliminar',
      button: true,
      cell: (row) => (
        <button onClick={() => removeLote(row.value)} className="btn btn-danger">
          X
        </button>
      ),
    },
  ];

  return (
    <div>
      <div className="form-group">
        <label>Seleccionar Desarrollo</label>
        <Select options={barrios} onChange={handleBarrioChange} />
      </div>

      {isLoading && <Spinner />}

      {lotes.length > 0 && (
        <div className="form-group">
          <label>Seleccionar Lote</label>
          <Select options={lotes} onChange={addLote} />
        </div>
      )}

      {selectedLotes.length > 0 && (
        <div>
          <h5>Lotes Seleccionados</h5>
          <DataTable columns={columns} data={selectedLotes} responsive />
        </div>
      )}
    </div>
  );
};

export default SelectLotes;
