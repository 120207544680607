import React from 'react'

const NameJuridicas = (props) => {
  const { client } = props;
  const { razonSocial } = client;
  return (
    <div className='mb-4'>
    <div className="row">
      <label>Razón Social:</label>
    </div>
      <div className="row">
        <div className="col-md-4">
          <label>RazonSocial</label>
          <input 
            type="text"
            className="form-control"
            value={razonSocial || '-'}  
            disabled
          />
        </div>
      </div>
    </div>
  )
}

export default NameJuridicas