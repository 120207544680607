import React, { useRef, useEffect } from 'react'

const InputRango = ({ index, name, handleInputChange, text, value }) => {

  const inputRef = useRef(null);

  useEffect(() => {
    if (value) {
      inputRef.current.value = value.join("-");
    }
  }, []);

  return <input
    type="text"
    className="form-control"
    placeholder={`${text} (e.g., 1-3)`}
    onChange={(e) => {
      if (!e.target.value) {
        handleInputChange(index, name, null);
        return;
      }
      let [min, max] = e.target.value.split("-").map(Number);
      if (min > max) {
        return;
      }
      if (!max) {
        handleInputChange(index, name, [min, min]);
        return;
      }
      handleInputChange(index, name, [min, max]);
    }}
    ref={inputRef}
  />
};

export default InputRango