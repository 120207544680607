import React, { useContext, useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { Link, withRouter } from "react-router-dom";
import clienteAxios from '../../config/axios';
import { CRMContext } from '../../context/CRMContext';
import Spinner from '../layout/Spinner';
import { saveAs } from 'file-saver';
import listadoBienesStore from '../../store/listadoBienesStore';
import { get } from '../../config/apiHelper';


const Bienes = (props) => {
  const [auth, guardarAuth] = useContext(CRMContext);
  let token = auth.token || localStorage.getItem("token");
  const { filter, setFilter, bienes, setBienes } = listadoBienesStore();
  const [bienesOriginal, setBienesOriginal] = useState([]);
  const [loading, setLoading] = useState(true);
    const [desarrollos, setDesarrollos] = useState([]);
  
    const getDesarrollos = async () => {
      try {
        const response = await get('/barrios?activo=true');
        setDesarrollos(response.map((desarrollo) => {
          return {
            label: desarrollo.nombre,
            value: desarrollo._id
          }
        }));
      } catch (error) {
        console.error(error);
      }
    }

  const getCurrency = (value) => {
    if (!Number(value)) {
      value = 0;
    }
    return new Intl.NumberFormat("es-AR", {
      currency: "ARS",
      style: "currency",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(value);
  }



  const getDate = (value) => {
    if (!value) {
      return '';
    }
    return new Intl.DateTimeFormat("es-Ar").format(value);
  }

  const getTipo = (value) => {
    const tipo = {
      inmueble: 'Inmueble',
      vehiculo: 'Vehículo',
    }
    return tipo[value] || 'Desconocido';
  }

  const getEstado = (value) => {
    return value.estado;
  }

  const getDetalle = (row) => {
    const { tipo, descripcion } = row;
    if (tipo === 'inmueble') {
      return `${descripcion && descripcion.type ? `Tipo: ${descripcion.type}` : 'Desconocido'}${descripcion && descripcion.direccion ? ` - Dirección: ${descripcion && descripcion.direccion}  ` : ''}`
    } else if (tipo === 'vehiculo') {
      return `Marca: ${descripcion.vehiculo?.brand} - Modelo: ${descripcion.vehiculo?.model} - Version: ${descripcion.vehiculo?.version} - Año: ${descripcion.vehiculo?.year}`
    }
  }

  const consultarAPI = async () => {
    try {
      const apiResponse = await clienteAxios.get("/bienes", {
        headers: {
          Authorization: `Bearer ${token}`
        },
      });

      setBienesOriginal(apiResponse.data.data);
      if (bienes.length === 0) {
        setBienes(apiResponse.data.data);
      }
      setLoading(false);
    } catch (error) {
      if (error.response?.status === 500) {
        localStorage.clear();
        props.history.push("/login");
      }
      console.log(error);
    }
  };

  useEffect(() => {
    consultarAPI();
    getDesarrollos();
  }, []);



  const columns = [
    {
      name: 'Alta',
      selector: 'fechaAlta',
      sortable: true,
      width: '10%',
      format: (row) => getDate(new Date(row.fechaAlta)), // Format the date for display
    },
    {
      name: 'Estado',
      selector: (row) => getEstado(row),
      sortable: true,
      width: '10%'
    },
    {
      name: 'Tipo',
      selector: (row) => getTipo(row.tipo),
      sortable: true,
      width: '10%'
    },
    {
      name: 'Proyeco Propietario',
      selector: (row) => row.proyectoPropietario?.nombre,
      sortable: true,
      width: '10%'
    },
    {
      name: 'Detalle',
      selector: (row) => getDetalle(row),
      sortable: true,
      width: '50%'
    },
    {
      name: 'Ver',
      button: true,
      cell: (row) => (
        <Link to={`/bienes/${row._id}`}>
          <button type="button" className="btn btn-block btn-success">
            Ver
          </button>
        </Link>
      ),
      width: '10%'
    },
  ];
  const data = bienes;

  const compareDates = (a, b, field) => {
    const dateA = new Date(a[field]);
    const dateB = new Date(b[field]);

    return dateA - dateB;
  };

  const handleSort = (column, direction) => {
    const sortedData = data.slice().sort((a, b) => {
      if (direction === 'asc') {
        return compareDates(a, b, column.selector);
      } else {
        return compareDates(b, a, column.selector);
      }
    });

    setBienes(sortedData);
  };

  const getExportedBienes = async () => {
    try {
      const response = await clienteAxios.get('/bienes/export', {
        responseType: 'blob',
      });
      const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      saveAs(blob, 'bienes.xlsx');

    } catch (error) {
      console.log(error);
    }
  }

  const updateFilter = (key, value) => {
    setFilter({ ...filter, [key]: value });
  }

  useEffect(() => {
    filterBienes();
  }, [filter]);

  const filterBienes = () => {
    if(!bienesOriginal.length) {
      return;
    }
    setBienes(bienesOriginal.filter(bien => {
      if (filter.tipo && bien.tipo !== filter.tipo) {
        return false;
      }
      if (filter.estado && bien.estado !== filter.estado) {
        return false;
      }
      if (filter.fechaInicio && new Date(bien.fechaAlta) < new Date(filter.fechaInicio)) {
        return false;
      }
      if (filter.fechaFin && new Date(bien.fechaAlta) > new Date(filter.fechaFin)) {
        return false;
      }
      if (filter.detalle && !getDetalle(bien).trim().toLowerCase().includes(filter.detalle.trim().toLowerCase())) {
        return false;
      }
      if (filter.proyectoPropietario && bien.proyectoPropietario?._id !== filter.proyectoPropietario) {
        return false;
      }
      return true;
    }))
  }


  if (loading) {
    return (
      <Spinner />
    )
  }



  return (
    <div className="card">
      <div className="card-header">
        <div className="row">
          <div className="col-md-8">
            <h3 className="card-title">Bienes</h3>
          </div>
          <div className="col-md-2">
            <button type="button" className="btn btn-block btn-success" onClick={getExportedBienes}>
              Exportar Bienes
            </button>
          </div>
          <div className="col-md-2">
            <Link to={`/bienes/nuevo`}>
              <button type="button" className="btn btn-block btn-success">
                Agregar Bien
              </button>
            </Link>
          </div>
        </div>
      </div>
      <div className="card-body">

        <div className="row mb-3">
          <div className="col-md-2">
            <label htmlFor="tipo">Tipo</label>
            <select
              className="form-control"
              id="tipo"
              name="tipo"
              onChange={(e) => updateFilter('tipo', e.target.value)}
              value={filter.tipo ?? ""}
            >
              <option value="">Todos</option>
              <option value="inmueble">Inmueble</option>
              <option value="vehiculo">Vehículo</option>
            </select>
          </div>
          <div className="col-md-2">
            <label htmlFor="estado">Estado</label>
            <select
              className="form-control"
              id="estado"
              name="estado"
              onChange={(e) => updateFilter('estado', e.target.value)}
              value={filter.estado ?? ""}
            >
              <option value="">Todos</option>
              <option value="Disponible">Disponible</option>
              <option value="Vendido">Vendido</option>
            </select>
          </div>
          <div className="col-md-2">
            <label htmlFor="estado">Proyecto Propietario</label>
            <select
              className="form-control"
              id="proyectoPropietario"
              name="proyectoPropietario"
              onChange={(e) => updateFilter('proyectoPropietario', e.target.value)}
              value={filter.proyectoPropietario ?? ""}
            >
              <option value="">Todos</option>
              {desarrollos.map((desarrollo) => (
                <option key={desarrollo.value} value={desarrollo.value}>{desarrollo.label}</option>
              ))}
            </select>
          </div>
          <div className="col-md-4">
            <label htmlFor="fechaAlta">Fecha de Alta</label>
            <div className="input-group">
              <input
                type="date"
                className="form-control"
                id="fechaInicio"
                name="fechaInicio"
                onChange={(e) => updateFilter('fechaInicio', e.target.value)}
                value={filter.fechaInicio ?? ''}
              />
              <span className="input-group-text">a</span>
              <input
                type="date"
                className="form-control"
                id="fechaFin"
                name="fechaFin"
                onChange={(e) => updateFilter('fechaFin', e.target.value)}
                value={filter.fechaFin ?? ''}
              />
            </div>
          </div>
          <div className="col-md-1">
            <button
              type="button"
              className="btn btn-block btn-secondary mt-4"
              onClick={() => setFilter({})}
            >
              Limpiar Filtros
            </button>
          </div>
        </div>

        <div className="row mb-3">
          <div className="col-md">
            <div className="col-md-12">
              <label htmlFor="descripcion">Detalle</label>
              <input
                type="text"
                className="form-control"
                id="descripcion"
                name="descripcion"
                onChange={(e) => updateFilter('detalle', e.target.value)}
                value={filter.detalle ?? ''}
              />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col">
            <DataTable columns={columns} data={bienes} pagination onSort={handleSort} defaultSortField="fechaAlta" defaultSortAsc={false} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(Bienes);